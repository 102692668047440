import React,{useEffect} from 'react';
import { Container } from 'react-bootstrap';
import styles from './CommingUp.module.scss'
import {useDimensions} from '../../logic/Dimentions';
import {connect} from 'react-redux';
import {useHistory } from 'react-router-dom';
import {fetchLiveSchedule} from '../../actions/LiveScheduleAction';


const CommingUp = ({liveSchedule,fetchLiveSchedule}) => {
    const { height, width } = useDimensions();
    const {location} = useHistory();
    let path = location.pathname;
    useEffect(()=>{
        if(liveSchedule.length==0){
            fetchLiveSchedule();
        }
    },[liveSchedule])

    return (
        <div className={path=='/'?styles.commingUpHome:styles.commingSwprayer}>
            <div className={styles.containerComingUp}>
                <div className={`row ${styles.container1}`}>
                    <div className="col-sm-3 comingUpCol">COMING UP</div>
                    <div className="col-sm-3">
                    <p>{liveSchedule.us?liveSchedule.us.caption:null}</p>
                    <h4>{liveSchedule.us?liveSchedule.us.name:null}</h4>
                    </div>
                    <div className="col-sm-3">
                    <p>{liveSchedule.eu?liveSchedule.eu.caption:null}</p>
                    <h4>{liveSchedule.eu?liveSchedule.eu.name:null}</h4>
                    </div>
                    <div className="col-sm-3">
                    <p>{liveSchedule.au?liveSchedule.au.caption:null}</p>
                    <h4>{liveSchedule.au?liveSchedule.au.name:null}</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {liveSchedule:state.liveSchedule}
}

export default connect(mapStateToProps,{fetchLiveSchedule})(CommingUp);