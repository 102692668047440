import React from "react";
import styles from "./BlogTitleBanner.module.scss";
import { Link } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../../logic/Dimentions';


const BlogTitleBanner = ({ newsHead }) => {
  const { height, width } = useDimensions();

  return (
    width>=992?<div className={styles.main_blog}>
      <div className="animated-prog-itemwrap">
      <div className="container">
        <div className={styles.blog_intro}>
          <div className={styles.cover_wrap}>
            <Link to={"/news/" + newsHead.blogUrl}>
              <img src={newsHead.blogImage} />
            </Link>
          </div>
          <div className={styles.content_wrap}>
            <div className="head_cont">
              {newsHead && newsHead.category.length > 0 ? (
                <p className="blog_cat">{newsHead.category[0].category}</p>
              ) : null}
            </div>
            <h1 className={styles.blog_cont}>
              <Link to={"/news/" + newsHead.blogUrl}>
                {newsHead ? newsHead.blogTitle : null}
              </Link>
            </h1>
            <p className={styles.blog_cont_desc}>
              {newsHead.blogDescription.substring(0, 270) + " . . ."}
            </p>
            <div className={styles.foot_cont}>
              <div className={styles.blog_auth}>
                <p>
                <img src={newsHead.blogImage} />
                  By
                  <label>
                    <a href="/blogauthor/renjith-leen">{newsHead.authorName}</a>{" "}
                  </label>
                </p>
              </div>
              <p className={styles.blog_date}>{newsHead.modifieddate}</p>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>:<div className={styles.blogHeaderMobile}>
      <Link to={'/news/'+newsHead.blogUrl}>
        <img src={newsHead.blogImage} />
        <h5>{newsHead.blogTitle}</h5>
        <div>
            <ul>
                <li>{newsHead.category[0].category}</li>
                <li>{newsHead.authorName}</li>
            </ul>
            <div className={styles.date}>{newsHead.createdDate}</div>
        </div>
      </Link>
    </div>
  );
};
export default BlogTitleBanner;
