import {FETCH_VIDEO,REMOVE_VIDEO} from './types';
import salomapi from '../apis';

export const fetchEpisodeVideo =  (videoName,region) => async dispatch => {
    const response = await salomapi.get('/episode/single?url='+videoName+'&region='+region);
    
    dispatch({type:FETCH_VIDEO,payload:response.data.episodes[0],maxLatestPage:response.data.episodes[0].latestPages,maxRelatedPage:response.data.episodes[0].relatedPages})
    
}
export const removeEpisodeVideo =  (videoName,region) => async dispatch => {
    
    dispatch({type:REMOVE_VIDEO,payload:[],maxLatestPage:null,maxRelatedPage:null})
    
}