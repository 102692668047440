import React from 'react';
import CommonLayout from '../components/Layouts/CommonLayout';
import ShowDetails from '../components/ShowDetails';

const ShowDetailsPage = () => {


    return (
        <CommonLayout>
            <ShowDetails/>
        </CommonLayout>
    )
}

export default ShowDetailsPage;