import React,{lazy} from 'react';
import { useHome } from '../logic/Home/useHome';
import WatchLiveVideo from '../components/WatchLiveVideo';
import {connect} from 'react-redux';
import {fetchOtherdetails} from '../actions/otherdetailsAction';
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../logic/Dimentions';
const OtherSection = lazy(()=>import('../components/OtherSection'));
const FindShalom = lazy(()=>import('../components/FindShalom'));
const Subscription = lazy(()=>import('../components/Subscription'));
const Footer = lazy(()=>import('../components/Footer'));
const BottomTab = lazy(()=>import('../components/BottomTab'));
const HomeLiveWatch = lazy(()=>import('../components/Mobile/HomeLIveWatch'));

const WatchLive = ({fetchOtherdetails,otherdetails}) => {
     useHome({fetchOtherdetails,otherdetails});
    const { height, width } = useDimensions();

    return (
        <>
        <WatchLiveVideo/>
        {width>=992?
        <>
        <OtherSection/>
        <FindShalom/>
        <Subscription/>
        <Footer/>
        </>
        :<>
        <HomeLiveWatch/>
        <BottomTab/></>}
        
        </>
    )
}
const mapStateToProps = (state) => {
    return ({otherdetails:state.otherdetails})
  }
export default connect(mapStateToProps,{fetchOtherdetails})(WatchLive);