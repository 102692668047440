import React from 'react';
import styles from './CommonLoader.module.scss';

const CommonLoader = () => {
    return (
        <div className={styles.loaderWrap}>
            <div className={styles.dotsAnim}></div>
            <div className={styles.dotsAnim}></div>
            <div className={styles.dotsAnim}></div>
        </div>
    )
}

export default CommonLoader;