import { useEffect } from 'react';
export const useProgramList = props => { 
    const {fetchPrograms,programes,category,categories,path,fetchCategoryPrograms} =props
    useEffect(()=>{
        if(path=='/'){
            if(programes?.length===0 || programes.bgImage)
            fetchPrograms('featured')
        }else{
            let cat=category;
            if(path=='/shows'){
                cat='popular';
            }
            if(programes[cat]==undefined || !programes.bgImage){
                fetchCategoryPrograms(cat)
                
            }
            
        }
    },[fetchPrograms,programes,categories,category])
}
