import {FETCH_TESTIMONIES} from '../actions/types';

const INTIAL_STATE = [];

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_TESTIMONIES:
            return action.payload;
        default:
            return state;
    }
}