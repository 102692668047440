import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styles from "./CountryDropdown.module.scss"

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const CountryDropDown = ({selectCountry,country}) =>{
 
    const classes = useStyles();
             return(
                 <div className={styles.select_wrap+ " "+ styles.location_box}>
                     <span>
                <FormControl variant="filled" className={classes.formControl}>
                <Select
                  inputProps={{ 'aria-label': 'Without label' }}
                  className={classes.selectEmpty}
                  displayEmpty
                  value={country}
                  onChange={selectCountry}
                  style={{'color':'white','margin': '0'}}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value='us'>USA</MenuItem>
                  <MenuItem value='eu'>UK</MenuItem>
                  <MenuItem value='ca'>CAN</MenuItem>
                  <MenuItem value='au'>AUS</MenuItem>
                  <MenuItem value='ir'>IRE</MenuItem>
                </Select>
              </FormControl>
              </span>
              </div>
             )
}
export default CountryDropDown;