import styles from './MailPage.module.scss';
import React,{useState,useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ReCAPTCHA from "react-google-recaptcha";
import StickyNewsHeader from '../StickyNewsHeader';
import {connect} from 'react-redux';
import {fetchMailFormDetails} from "../../actions/fellowshipAction"
import BrowserPersistence from '../../util/simplePersistance';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import MonthlyPledge from '../MonthlyPledge';
import SingleGift from '../SingleGift';

const storage = new BrowserPersistence();
const MailPage = ({fetchMailFormDetails,mailFormDetails}) => {
  const [value, setValue] = useState(0);
  useEffect(()=>{
    // if(fellowship.length ==0)
     fetchMailFormDetails(storage.getItem('CountryCode'))
},[])
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      function TabPanel(props) {
        const { children, value, index, ...other } = props;
        
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
             <Box p={3}>
             <Typography>{children}</Typography>
            </Box>
            )}
          </div>
        );
      }
    return (
        <div className={styles.spfmail_intro}>
            <Container>
                <h3>{mailFormDetails.pageHeading}</h3>
                <p>{mailFormDetails.pageDescription}</p>

                
            <Tabs aria-label="simple tabs example" onChange={handleChange} value={value} className={styles.spfmail_tabs}>
                <Tab label={
                            <a>
                               <h3>Monthly Pledge</h3> 
                            </a>
                           } 
                           className={styles.periodic_tab}>
                </Tab>
                <Tab label={
                            <a>
                               <h3>Single Gift</h3>  
                            </a>
                           }  
                           className={styles.periodic_tab} />
            </Tabs>

            <TabPanel index={0} value={value} className={styles.tab_panel_mail}>
              <MonthlyPledge MonthlyDescription={mailFormDetails.MonthlyDescription} clubLevels={mailFormDetails.clubLevels}/>
            </TabPanel>
            <TabPanel index={1} value={value} className={styles.tab_panel_mail}>
              <SingleGift/>
            </TabPanel>
            </Container>

        </div>        
    )

}

const mapPropsToState = (state) => {
  return {mailFormDetails:state.fellowshipData.mailFormDetails}
}
export default connect(mapPropsToState,{fetchMailFormDetails})(MailPage);