import React,{useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import style from './EpisodeVideo.module.scss';
import Episodethumb from '../EpisodeThumb';
import EpisodeThumbMobile from '../Mobile/EpisodeThumb';
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../../logic/Dimentions';



const EpiosdeRelatedVideo = ({episodeVideo})=>{

    const [tabActive,SetTabActive] = useState(1);
    const { height, width } = useDimensions();

    return (
        <div className={style.episodeRelated}>
        <Container>
            <ul className={style.tabs}>
                <li className={tabActive==1?style.active:null} onClick={()=>{SetTabActive(1)}}>Latest Video</li>
                <li className={tabActive==2?style.active:null} onClick={()=>{SetTabActive(2)}}>Related Video</li>
            </ul>
            <Row>
            {tabActive==1&&episodeVideo.latestEpisodes?episodeVideo.latestEpisodes.map((value)=>{  
            // return(width<992?<EpisodeThumbMobile value={value}/>:<Episodethumb value={value}/>)
            return(<Episodethumb value={value}/>)
            }):null}
            {tabActive==2&&episodeVideo.relatedEpisodes?episodeVideo.relatedEpisodes.map((value)=>{  
            // return(width<992?<EpisodeThumbMobile value={value}/>:<Episodethumb value={value}/>)
            return(<Episodethumb value={value}/>)
            }):null}
            
            </Row>
        </Container>  
        </div>
        
    )
}

export default EpiosdeRelatedVideo;