import {FETCH_BLOG_CATEGORIES,FETCH_BLOG_LIST,FETCH_NEWS_DESCRIPTION} from './types';
import {salomapi3} from '../apis';
import salomapi from '../apis';

export const fetchBlogCategories = () => async dispach => {
    const response = await salomapi3.get('/blog/categories');
    dispach({type:FETCH_BLOG_CATEGORIES,payload:response.data.categories})
}
export const fetchBlogList = (loadPage) => async dispach => {
    const response = await salomapi.get('blog?perpage=14&page='+loadPage+'&category=all');
    dispach({type:FETCH_BLOG_LIST,payload:response.data.blogs})
}
export const fetchNewsDescription = (url) => async dispach => {
    const response = await salomapi.get('/blog/single?url='+url);
    
    dispach({type:FETCH_NEWS_DESCRIPTION,payload:response.data})
}