import React from "react"
import styles from './ThanksforSubmission.module.scss';
import thankyou from "../../assets/img/thankyou1.png"

const ThanksforSubmission = ({alertClose}) =>{
   
  return(
      <div className={styles.ThanksforSubmission}>
      <div className={styles.alertBotton} id="successalert">
          <h4 className={`row ${styles.alertHead}`}>
              <div className="col-lg-9">
              <img alt="" src={thankyou} className={styles.thankImg} />
                  </div><div className={`col-lg-2 ${styles.closeAlert}`}>
                      <button type="button" className={styles.close} onClick={alertClose}>×</button>
                      </div></h4><p className={styles.alertP}> The intention you have submitted will be remembered during Holy Mass.</p>
                      <p className={styles.alertP}> If you would like to support the prayer initiatives of the channel, please click the link below.</p>
                      <a className={styles.web_btn} data-toggle="tooltip" title="SUPPORT" href="https://payments.shalommedia.org/tvdonation" target="_blank">
                          SUPPORT</a></div>
                          </div>
  )
}
export default ThanksforSubmission