import React,{useEffect,useCallback} from 'react';
import {connect} from 'react-redux';
import {fetchPrograms,fetchCategoryPrograms} from '../../actions/programeAction';
import Row from 'react-bootstrap/Row';
import ProgrameThumb from '../ProgrameThumb';
import {useProgramList} from "../../logic/ProgramList/useProgramList";
import { useParams,useHistory } from "react-router-dom";
import styles from './ProgramList.module.scss';
import _ from 'lodash';
import ShowListShimmer from '../Shimmers/ShowListShimmer/ShowListShimmer';
import CommonLoader from '../Shimmers/CommonLoader';

const ProgramList = ({fetchPrograms,programes,categories,fetchCategoryPrograms,categoryProgrames}) => {


        const {location} = useHistory();
        let path = location.pathname;
        let {category} = useParams()
        useProgramList({fetchPrograms,programes,category,categories,path,fetchCategoryPrograms})
        if(path=='/'){
            category = 'featured';
        }else if(path=='/shows'){
            category = 'popular' 
        }
        
        
    return(
        <div className={styles.ProgramListRow}>
            <Row>
            {programes && programes[category] && programes[category]?.length > 0 ?programes[category]?.map((value)=>{  
                return(<ProgrameThumb value={value} key={value.url}/>)
            }):  _.times(10, ()=><ShowListShimmer/>)}
          
            </Row>
        </div>
        
    )
}

const mapStateToProps = (state) =>{
return ({programes:state.programes,categories:state.categories})
}

export default connect(mapStateToProps,{fetchPrograms,fetchCategoryPrograms})(ProgramList);