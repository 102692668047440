import {FETCH_PROGRAMES} from './types';
import salomapi from '../apis';
import BrowserPersistence from '../util/simplePersistance';


const storage = new BrowserPersistence();

export const fetchPrograms = (category,region) => async dispatch => {
    async function fetchFromServer() {
        try {
                const response = await salomapi.get('/featured/programs?region='+region+'&category='+category);
                var data = {[category]:response.data.programs}  
                dispatch({type:FETCH_PROGRAMES,payload:data})
                await savePrograms(data);
                } 
                catch (error) {
                dispatch({type:FETCH_PROGRAMES,payload:error});
                throw error;
            }
    }
    const localHeaderData = await retrievePrograms();
    if(localHeaderData)
    dispatch({type:FETCH_PROGRAMES,payload:localHeaderData})
    else
    await fetchFromServer();
    
    async function retrievePrograms() {
        return storage.getItem('programData');
      }
    async function savePrograms(data) {
        return storage.setItem('programData', data,86400);
      }
}

export const fetchCategoryPrograms = (category) => async dispach => {
    const response = await salomapi.get('/category/program',{params:{region:storage.getItem('CountryCode'),category}})
    let data = {[category]:response.data.programs}
    dispach({type:FETCH_PROGRAMES,payload:data,bgImage:response.data.bgImage})
}

export const fetchProgramDetails = (url) => async dispach => {
    const response = await salomapi.get('/program/single',{params:{url}})
    return response.data;
}