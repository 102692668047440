import { useEffect } from 'react';
export const useHomeLiveVideo = props => { 
    const {fetchToken,getSteamUri,region,playError,setPlayError,watchUrl,setWatchUrl} = props

    useEffect(()=>{
        if(playError){
            fetchToken().then((value)=>{
                getSteamUri(value,region).then((value2)=>{
                    setWatchUrl(`${value2.url}?token=${value.secure_token}&timestamp=${value.timestamp}&clientId=${value.client_id}`)
                })
            })
        }
        
    },[region,playError])
    return {
        watchUrl
    }
}