import React,{lazy} from 'react';
import ApplyNow from '../components/ApplyNow';
const Menu = lazy(()=>import('../components/Menu'));
const Subscription = lazy(()=>import('../components/Subscription'));
const Footer = lazy(()=>import('../components/Footer'));

const ApplyNowPage = () => {
  
    return (
        <>
        <Menu/>
        <ApplyNow/>
        <Subscription/>
        <Footer/>
        </>
    )
}

export default ApplyNowPage;