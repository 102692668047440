import React from 'react';
import CommonLayout from '../components/Layouts/CommonLayout';
import { useHome } from '../logic/Home/useHome';
import AboutUs from '../components/AboutUs';
import {connect} from 'react-redux';
import {fetchOtherdetails} from '../actions/otherdetailsAction';

const About = ({fetchOtherdetails,otherdetails}) => {
     useHome({fetchOtherdetails,otherdetails});
    return (
        <CommonLayout>
        <AboutUs/>
        </CommonLayout>
        
    )
}
const mapStateToProps = (state) => {
  return ({otherdetails:state.otherdetails})
}
export default connect(mapStateToProps,{fetchOtherdetails})(About);