import { act } from 'react-dom/cjs/react-dom-test-utils.production.min';
import {FETCH_PROGRAMES} from '../actions/types';

const INTIAL_STATE = [];

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_PROGRAMES:
            let data = action.payload;
            return {...state,...data,bgImage:action.bgImage};
        default:
            return state;
    }
}