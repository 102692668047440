import React,{lazy} from 'react';
import EpisodeVideo from '../components/EpisodeVideo';
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../logic/Dimentions';
const Subscription = lazy(()=>import('../components/Subscription'));
const Footer = lazy(()=>import('../components/Footer'));
const BottomTab = lazy(()=>import('../components/BottomTab'));


const EpisodeVideoPage = () => {
    const { height, width } = useDimensions();

    return (
        <>
        <EpisodeVideo/>
        {width>=992?
        <>
        <Subscription/>
        <Footer/>
        </>:<BottomTab/>}
        </>
    )
}

export default EpisodeVideoPage;