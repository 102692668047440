import React,{lazy} from 'react';
import './CommonStyle.scss';
import {useDimensions} from '../../logic/Dimentions';
import {isMobile} from 'react-device-detect';


const Menu = lazy(()=>import('../Menu'));
const Donate = lazy(()=>import('../Donate'));
const OtherSection = lazy(()=>import('../OtherSection'));
const Subscription = lazy(()=>import('../Subscription'));
const Footer = lazy(()=>import('../Footer'));
const MobileMenu =  lazy(()=>import('../MobileMenu'));
const BottomTab = lazy(()=>import('../BottomTab'));

const CommonLayout = ({children}) => {
    const { height, width } = useDimensions();
    return (
        <>
        {width>1024?<Menu/>:<MobileMenu/>}
        <div>{children}</div>
        {width>=992?
        <>
        <OtherSection/>
        <Donate/>
        <Subscription/>
        <Footer/></>:<BottomTab/>}
        </>
    )
}

export default CommonLayout;