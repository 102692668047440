import {FETCH_PEACE_FELLOWSHIP,FETCH_MAIL_FORM_DETAILS} from './types';
import salomapi from '../apis';

export const fetchPeaceFellowship =  () => async dispatch => {
    const response = await salomapi.get('/peacefellowship');
    dispatch({type:FETCH_PEACE_FELLOWSHIP,payload:response.data})
}

export const fetchMailFormDetails =  (region) => async dispatch => {
    const response = await salomapi.get('/spf/mail?region='+region);
    dispatch({type:FETCH_MAIL_FORM_DETAILS,payload:response.data})
}