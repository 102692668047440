import styles from './AddressForm.module.scss';
import MailPrintForm from "../MailPrintForm"
import React,{useState,useEffect,useCallback,useRef} from 'react';
import Container from 'react-bootstrap/Container';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {fetchCountryList,fetchStateFromCountry} from '../../actions/swprayerAction'
import { makeStyles } from "@material-ui/core/styles";
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import { useFormik } from 'formik';
import ReCAPTCHA from "react-google-recaptcha";
import classNames from 'classnames'
import {useReactToPrint} from 'react-to-print';


const titleArray = [
    "Mr",
    "Miss",
    "Mrs",
    "Ms",
    "Prof",
    "Dr",
    "Rev. Fr",
    "Sr",
    "Rev Msgr",
    "The Most Rev",
    "H.E",
    "Dcn",
    "None"
  ];
  const useStyles = makeStyles((theme) => ({
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        // Default left padding is 6px
        paddingLeft: 26,
        color: "black",
      }
    }
  }));
const AddressForm = ({fetchCountryList,fetchStateFromCountry,countryList,stateList,monthlyValue,tabValue}) => {
    const [countryName, setCountryName] = useState("");
    const [countryInputName, setCountryInputName] = useState("");
    const [isFormSuccess, setFormSuccess] = useState(false);
    const [state, setState] = useState("");
    useEffect(()=>{
        if(countryList.length ==0)
        fetchCountryList()
    },[])
     const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
    const divClass = classNames(styles.div_fields, 'col-sm-4')
    const inputClass = classNames(styles.input_fields)
    const firstField = classNames(styles.div_fields, 'col-sm-8')
    const otherField = classNames(styles.div_fields, 'col-sm-6')
    const classes = useStyles();
    const componentRef = useRef();
    const validate = values => {
        const errors = {};
        if(!monthlyValue && tabValue=='Monthly')
        errors.title = 'Required'
        if (!values.firstName) {
          errors.firstName = 'Required';
        } else if (values.firstName.length > 15) {
          errors.firstName = 'Must be 15 characters or less';
        }
      
        if (!values.lastName) {
          errors.lastName = 'Required';
        } 
        else if (values.lastName.length > 20) {
          errors.lastName = 'Must be 20 characters or less';
        }
      
        // if (!values.email) {
        //   errors.email = 'Required';
        // } 
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = 'Enter valid Eamil Id';
        }
        if (countryName =="") {
          errors.country = 'Required';
        } 
        if (!values.address) {
          errors.address = 'Required';
        } 
        if (!values.phone) {
            errors.phone = ' ';
          } 
        else if(values.phone.length <7 || values.phone.length>15)
        errors.phone = 'Enter valid phone number';

        // if (!values.phone2) {
        //   errors.phone2 = null;
        // } 
        // else
        if(values.phone2 && values.phone2.length >0){
          if(values.phone2.length <7 || values.phone2.length>15)
          errors.phone2 = 'Enter valid phone number';
        }
        

        if (!values.address) {
          errors.address = 'Required';
        } 
        if (!values.apartment) {
          errors.apartment = 'Required';
        } 
        if (!values.city) {
          errors.city = 'Required';
        } 
        if(!state) {
          errors.state = 'Required';
        }
        if (countryInputName =="") {
          errors.country = 'Required';
        } 
        console.log("dddddddddddddddddd",errors)
        return errors;
      };
    const formik = useFormik({
        initialValues: {
          email: '',
        },
        validate,
        onSubmit: values => {
        
          setFormSuccess(true)
        //    const postData = {
        //     firstname : values.firstName,
        //     lastname : values.lastName,
        //     email    :values.email,
        //     city :values.city,
        //     state: state,
        //     country:countryInputName,
        //     message:values.description,
        //     publish:0,
        //     type:"feedback"
    
        //    }
        //    salomapi.post('/send/testimony',postData).then( response =>{
        //    })
          //  alert(JSON.stringify(values, null, 2));
        },
      });
      const CountryChange = useCallback((event, newValue) => {
       
        if(newValue){
           
            fetchStateFromCountry(newValue.id)
            setCountryName(newValue.country_name);
        }
        
     },[])
     const CountryInputChange = useCallback((event, newInputValue) => {
       
      setCountryInputName(newInputValue);
  
},[])
  
     const StateChange = (event, newValue) => {
        if(newValue)
        setState(newValue.name)
       // setValue(newValue);
      }
    return (
        <section className={styles.spfmail_form}>
            <Container>
                <h3>Name & Address</h3>

                <form className={styles.form_align} onSubmit={formik.handleSubmit}>
                    <Row className={styles.row_align}>
                        <div className={divClass}>
                            <select className={inputClass} onChange={formik.handleChange} name="title">
                                {
                                    titleArray.map((title) =>{
                                        return(
                                            <option  value={title}> {title}</option>
                                        )
                                        
                                    })
                                }
                            </select>
                            {/* <input className={inputClass} type="text" name="Title" placeholder="Title *"></input> */}
                        </div>
                        <div className={firstField}>
                            <input className={inputClass} type="text" onChange={formik.handleChange} value={formik.values.firstName} name="firstName" placeholder="First name*"></input>
                        </div>
                    </Row>
                    <Row className={styles.row_align}>
                        <div className={otherField}>
                            <input className={inputClass} type="text" onChange={formik.handleChange} value={formik.values.lastName} name="lastName" placeholder="Last Name"></input>
                        </div>
                        <div className={otherField}>
                            <input className={inputClass} type="email" onChange={formik.handleChange} value={formik.values.email}  name="email" placeholder="Email *"></input>
                            {formik.errors.email}
                        </div>
                    </Row>  
                    <Row className={styles.row_align}>
                        <div className={otherField}>
                            <input className={inputClass} type="text" onChange={formik.handleChange} value={formik.values.phone}  name="phone" placeholder="Primary Phone*"></input>
                            {formik.errors.phone}
                        </div>
                        <div className={otherField}>
                            <input className={inputClass} type="text" onChange={formik.handleChange} value={formik.values.phone2}  name="phone2" placeholder="Mobile Phone (optional)"></input>
                            {formik.errors.phone2}
                        </div>
                    </Row>
                    <Row className={styles.row_align}>
                        <div className={otherField}>
                            <input className={inputClass} type="text" onChange={formik.handleChange} value={formik.values.address}  name="address"  placeholder="Street Address *"></input>
                        </div>
                        <div className={otherField}>
                            <input className={inputClass} type="text" onChange={formik.handleChange} value={formik.values.apartment}  name="apartment" placeholder="Apt, Suite, Bldg (optional)"></input>
                        </div>
                    </Row>  
                    <Row className={styles.row_align}>
                        <div className={otherField}>
                        <Autocomplete
     
                            id="combo-box-demo"
                            classes={classes}
                            options={countryList}
                            getOptionLabel={(option) => option.country_name}
                            renderOption={(option) => (
                            <div style={{ color: "white" }}>{option.country_name}</div>
                                    )}
                                style={{ width: 404 }}
                                inputValue={countryInputName}
                                onInputChange={CountryInputChange}
                                onChange={CountryChange}
                                renderInput={(params) => <TextField className={inputClass} {...params} placeholder="Select Country" name="country" variant="outlined" />}
                            />
                            {/* <input className={inputClass} type="text" name="Select Country"  placeholder="Select Country" ></input> */}
                        </div>
                        <div className={otherField}>
                        <Autocomplete
                            id="combo-box-demo"
                            classes={classes}
                            options={stateList}
                            getOptionLabel={(option) => option.name}
                            renderOption={(option) => (
                            <div style={{ color: "white" }}>{ option.name}</div>
                            )}
                            style={{ width: 404 }}
                            onChange={StateChange}
                            renderInput={(params) => <TextField  className={inputClass} {...params} placeholder="Select State" variant="outlined" />}
                        />
                            {/* <input className={inputClass} type="text" placeholder="Select State"></input> */}
                        </div>
                    </Row>  
                    <Row className={styles.row_align}>
                        <div className={otherField}>
                            <input className={inputClass} type="text" onChange={formik.handleChange} value={formik.values.city} name="city"  placeholder="City*" ></input>
                        </div>
                        <div className={otherField}>
                            <input className={inputClass} type="text" onChange={formik.handleChange} name="zipcode" value={formik.values.zipcode} placeholder="Zip code"></input>
                        </div>
                    </Row>   
                    <Row className={styles.row_align}>
                        <ReCAPTCHA className={styles.captchaClass}
                        sitekey="Your client site key"
                        />
                    </Row>  
                    {Object.keys(formik.errors).length!=0 && formik.submitCount  ? (
                     <div className="form-group col-sm-12">
                        <span className={styles.resonseFailedMsg}>
                            Please fill all required fields.
                         </span>
                        </div>
                    ) : null}
                   
                    <div className={`col-sm-12${styles.form_cont}`}>
               
                    <button className={styles.mnthly_btn} type="submit" onClick={Object.keys(formik.errors).length==0 ? handlePrint:null}>SUBMIT FORM</button>
                
       
                     <MailPrintForm printData={formik.values} country={countryName} state={state} monthlyValue={monthlyValue}  type={tabValue} currCode="USD" ref={componentRef} />
                    </div>
                </form>
            </Container>
        </section>
    )

}
const mapStateToProps = (state) => {
    return ({countryList:state.swprayer.countryList,stateList:state.swprayer.stateList,})
}

export default connect(mapStateToProps,{fetchCountryList,fetchStateFromCountry})(AddressForm);
