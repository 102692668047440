import {FETCH_COMMON_SEARCH,FETCH_MENU_LIST,FETCH_SEC_MENU_LIST} from './types';
import salomapi from '../apis';
import BrowserPersistence from '../util/simplePersistance';

const storage = new BrowserPersistence();
export const fetchPrimaryMenu =  () => async dispatch => {

    async function fetchFromServer() {
        try {
            const response = await salomapi.get('menus');
            dispatch({type:FETCH_MENU_LIST,payload:response.data.menu})
            await saveMenu(response.data.menu);
        } catch (error) {
         dispatch({type:FETCH_MENU_LIST,payload:error});
        throw error;
      }
    }
    const localMenuData = await retrieveMenu();
    if(localMenuData)
    dispatch({type:FETCH_MENU_LIST,payload:localMenuData})
    else
    await fetchFromServer();
    async function retrieveMenu() {
        return storage.getItem('menuList');
      }
      
      async function saveMenu(menu) {
        return storage.setItem('menuList', menu,86400);
      }
    // const response = await salomapi.get('menus');
    // dispatch({type:FETCH_MENU_LIST,payload:response.data.menu})
    
}

export const fetchSecMenu = () => async dispatch => {
    const response = await salomapi.get('secondarynav');
    dispatch({type:FETCH_SEC_MENU_LIST,payload:response.data.menu});

}

export const fetchCommonSearchResult =  (search,type) => async dispatch => {
    const response = await salomapi.get('/search?s='+search+'&type='+type+'&page=1&perpage=10');
    dispatch({type:FETCH_COMMON_SEARCH,payload:response.data.result})
    
}