export const FETCH_BANNER = 'FETCH_BANNER';
export const FETCH_PROGRAMES = 'FETCH_PROGRAMES';
export const FETCH_OTHER_DETAILS = 'FETCH_OTHER_DETAILS';
export const FETCH_LATEST_EPISODES = 'FETCH_LATEST_EPISODES';
export const FETCH_CURRENCY = 'FETCH_CURRENCY';
export const FETCH_FOOTER = 'FETCH_FOOTER';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_VIDEO = 'FETCH_VIDEO';
export const FETCH_CAREERS = 'FETCH_CAREERS';
export const FETCH_CONTACTUS = 'FETCH_CONTACTUS';
export const FETCH_BLOG_CATEGORIES = 'FETCH_BLOG_CATEGORIES'
export const FETCH_BLOG_LIST= 'FETCH_BLOG_LIST';
export const FETCH_MENU_LIST = 'FETCH_MENU_LIST';
export const FETCH_CATEGORY_EPISODES = 'FETCH_CATEGORY_EPISODES';
export const FETCH_LATEST_VIDEOS = 'FETCH_LATEST_VIDEOS';
export const FETCH_RELATED_VIDEOS = 'FETCH_RELATED_VIDEOS';
export const REMOVE_LATEST_RELATED_VIDEOS = 'REMOVE_LATEST_RELATED_VIDEOS';
export const FETCH_ABOUTUS = 'FETCH_ABOUTUS';
export const FETCH_MORE_ENDORSEMENTS = 'FETCH_MORE_ENDORSEMENTS';
export const FETCH_MORE_JOBS = 'FETCH_MORE_JOBS';
export const FETCH_LIVE_SCHEDULE = 'FETCH_LIVE_SCHEDULE';
export const FETCH_SWPRAYERDETAILS = 'FETCH_SWPRAYERDETAILS';
export const FETCH_TESTIMONIES = 'FETCH_TESTIMONIES';
export const FETCH_DAILYMASS = 'FETCH_DAILYMASS';
export const FETCH_SWPRAYER = 'FETCH_SWPRAYER';
export const FETCH_SEC_MENU_LIST = 'FETCH_SEC_MENU_LIST';
export const FETCH_PEACE_FELLOWSHIP = 'FETCH_PEACE_FELLOWSHIP'; 
export const FETCH_MEDIA_GALLERY = 'FETCH_MEDIA_GALLERY';
export const FETCH_COUNTRY_LIST = 'FETCH_COUNTRY_LIST';
export const FETCH_STATE_FROM_COUNTRY = 'FETCH_STATE_FROM_COUNTRY';
export const FETCH_TIME_FROM_DATE = 'FETCH_TIME_FROM_DATE';
export const FETCH_WATCHON_DETAILS = 'FETCH_WATCHON_DETAILS';
export const FETCH_NEWS_DESCRIPTION = 'FETCH_NEWS_DESCRIPTION';
export const FETCH_MAIL_FORM_DETAILS = 'FETCH_MAIL_FORM_DETAILS';
export const FETCH_CAREER_DETAILS = 'FETCH_CAREER_DETAILS';
export const FETCH_FULL_SCHEDULE = 'FETCH_FULL_SCHEDULE';
export const FETCH_COMMON_SEARCH = 'FETCH_COMMON_SEARCH';
export const FETCH_WATCH_LIVE_IMAGES = 'FETCH_WATCH_LIVE_IMAGES';
export const FETCH_SOCIALLINK = 'FETCH_SOCIALLINK';
export const REMOVE_VIDEO = 'REMOVE_VIDEO';
