import React, { useState } from 'react';
import styles from './EpisodeThumb.module.scss';
import { FaPlayCircle,FaPauseCircle,FaPlay } from "react-icons/fa";
import { useViewportAnimation } from "../../logic/ViewportAnimation/useViewportAnimation";
import {useDimensions} from '../../logic/Dimentions';
import { Link } from 'react-router-dom';
const EpisodeThumb = ({value}) => {
    const [playShown,setPlayShown] = useState(false)
    const [isLoaded,setLoaded] = useState(false)
    const { height, width } = useDimensions();
    useViewportAnimation()
    const onLoad = () =>{
        setLoaded(true)
    }
    return (
        <div className={`${styles.EpisodeThumb} col-md-4 animation`}>
            <Link to={`/episode/${value.url}`} activeClassName="active">
                <figure className={ ` ${styles.imgHoverAnim}    ${styles.imgWrap} ${styles.wide}` }  onMouseEnter={() => setPlayShown(true)} onMouseLeave={() => setPlayShown(false)}>
                <img className={isLoaded ? "loaded":null} src={value.image} onLoad={onLoad}/>
                


                {playShown || width<992? (<><span className={styles.icon_play_video}>
               
                    </span><p className="episode-time">
                        {/* <a className="IconPlaySeason-iconPlayP-0-1-138 IconPlaySeason-iconPlayP-0-1-140">
                        <i className="icon-watch-later"></i>Watch Later</a> */}
                        <a className=" IconPlaySeason-iconPlayP-0-1-138 IconPlaySeason-iconPlayP-0-1-140">{value.duration}</a>
                </p></>):null}
                </figure>
                


            <div className={styles.contentBox}>
                <h3 className={styles.title}>{value.title}</h3>
                <p className={styles.subtitle}>{`E${value.episode} - ${value.program}`}</p>
            </div>
            </Link>
        </div>
    )
}

export default EpisodeThumb;