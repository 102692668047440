import React,{lazy} from 'react';
import BlogList from '../components/BlogList';

import MailPage from '../components/MailPage';
const Menu = lazy(()=>import('../components/Menu'));
const Subscription = lazy(()=>import('../components/Subscription'));
const Footer = lazy(()=>import('../components/Footer'));

const Mail = () => {
  
    return (
        <>
        <Menu/>
        <MailPage/>
        <Subscription/>
        <Footer/>
        </>
    )
}

export default Mail;