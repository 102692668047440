import {FETCH_CURRENCY} from '../actions/types.js';

const INTIAL_STATE = [];

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_CURRENCY:
            return action.payload;
        default:
            return state;
    }
}