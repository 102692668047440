import React, { useCallback } from 'react';
import {connect} from 'react-redux';
import Container from 'react-bootstrap/Container';
import styles from './CurrentOpenings.module.scss';
import {fetchMoreJobs} from '../../actions/careersAction';
import { Link } from 'react-router-dom';
const CurrentOpenings = ({data,loadMoreJobs,maxPage,currentPage}) => {    
    return (
                    <Container>
                        <div className="row">
                        {data?data.map((value)=>{ 
                            return(
                            <div className="col-md-4 col-xs-6">
                                    <div className={styles.career_wrapper}>
                                    <span className={styles.span_wrap}>{value.superHeading} Openings</span>
                                    <h3 className={styles.h3_wrap}>{value.jobTitle}</h3>
                                    <p className={styles.para_wrap}>{value.description}</p>
                                    <label className={styles.opening_wrap}>{value.jobTitle}</label>
                                    <Link className={styles.web_btn} to={`/career/${value.url}`}>APPLY NOW</Link>
                                    </div>
                            </div>   
                           
                            )
                            }):null}
                                    { currentPage<maxPage ? (<div className={styles.loadMoreContainer}> 
                                         <button className={styles.load_btn} onClick={loadMoreJobs}>LOAD MORE</button>
                                    </div> ) :null } 
                        </div>        
                    </Container>
    )
}
const mapStateToProps = (state) => {
    return ({careers:state.careers.moreJobs,maxPage:state.careers.maxPage,currentPage:state.careers.page})
}

export default connect(mapStateToProps,{fetchMoreJobs})(CurrentOpenings);