import styles from './MonthlyPledge.module.scss';
import React,{useState} from 'react';
import Container from 'react-bootstrap/Container';
import AddressForm from '../AddressForm';

const MonthlyPledge = ({MonthlyDescription,clubLevels}) => {
    const [isDisabled, setDisabled] = useState(true);
    const [monthValue, setMonthValue] = useState("");
    const onChangeValue = (event) => {
        if(!event.target.value){
            setMonthValue(event.target.value)
            setDisabled(false)
        }
        else{
            setMonthValue(event.target.value)
            setDisabled(true)
        }
        
      }
    const changeMonthAmount = (e) =>{
        setMonthValue(e.target.value)
    }
    return (
        <>
        <div className={styles.spfmail_content}>
            <Container>
                    <p>{MonthlyDescription}</p>
                    <div className={styles.clublevel_wrap} >
                        <h5>Select any amount.</h5>
                        <div onChange={onChangeValue}>
                            {
                                clubLevels && clubLevels.length > 0 ?  clubLevels.map((level) =>{
                                     return(
                                         <>
                                        <input type="radio" value={level.level} name="usdpermonth"/>
                                        <span>
                                           {level.level}  {level.levelType}
                                        </span>
                                        <br/><br />
                                         </>
                                     )
                                })
                                   
                                 :null
                            }
                        </div>

                    </div>
                    <div className={styles.month_amt}>
                        <h5>Monthly Amount USD</h5>
                        <input type="number" disabled={isDisabled} value={monthValue} onChange={changeMonthAmount} name="monthlyAmount"></input>
                    </div>
            </Container>
        </div>  
        <AddressForm monthlyValue={monthValue} tabValue={'Monthly'}/>
        </>

    )

}


export default MonthlyPledge;