import React from 'react';

import styles from './Indicator.module.scss';

const LoadingIndicator = props => {
  
  const className = props.global ? styles.global : styles.root;
  return (
    <div className={className}>
      <div className={styles.loading_content}>
        <div className={styles.loading_ico} />
      </div>
    </div>
  );
};

export default LoadingIndicator;
