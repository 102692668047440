import React,{useEffect,useState} from 'react';
import styles from './WeekSchedule.module.scss';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import {fetchLiveSchedule2} from '../../actions/LiveScheduleAction';
import {connect} from 'react-redux';
import moment from 'moment';
import BrowserPersistence from '../../util/simplePersistance';
import _ from 'lodash';

const storage = new BrowserPersistence();
const WeekSchedule = ({fetchLiveSchedule2,fullSchedule,liveSchedule}) => {

    useEffect(()=>{
        if(fullSchedule.length==0){
            fetchLiveSchedule2(storage.getItem('CountryCode'))
        }
    },[fullSchedule])

    const [region,setRegion] = useState(storage.getItem('CountryCode'))


    const regionChange = (e) => {
        setRegion(e.target.value)
    }

    return (
        <Container style={{position:'relative'}}>
            <div className={styles.selWrap}>
            <select className={styles.scheduleList} onChange={(e)=>{regionChange(e)}}>
                {_.toArray(liveSchedule).map((value,index)=>{
                    return(
                        <option value={value.slug}>{value.caption} ({value.name})</option>
                    )
                })}
            </select>
            </div>
            
            <table className={styles.tab_content}>
                <thead>
                    <tr>
                        <th>
                            <p>TIME</p>
                        </th>
                        {_.toArray(fullSchedule).length>0?fullSchedule[region]['00:00 AM'].map((value)=>{
                            return(
                                <th>
                                    <label>
                                        {moment(value.date).format("MMM DD")}
                                        </label>
                                    <p className={styles.fullweekPara}>{moment(value.date).format("ddd")}</p>
                                </th>
                            )
                        }):null}
                    </tr>
                </thead>

                <tbody>
                    {_.toArray(fullSchedule).length>0?_.toArray(fullSchedule[region]).map((value,key)=>{
                        return (
<tr>
                        {value.map((value2,index)=>{
                            return (
                        <>
                        {index==0?<td>{value2.start}</td>:null}
                        <td>{value2.name}</td>
                        </>
                            )
                        })}
                        
                    </tr>
                        )
                    }):null}
                    
                    
                </tbody>
            </table>
        </Container>
        
    )

}

const mapStateToProps = (state) => {
    return {fullSchedule:state.fullSchedule,liveSchedule:state.liveSchedule}
}

export default connect(mapStateToProps,{fetchLiveSchedule2})(WeekSchedule);