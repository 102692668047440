import React,{useEffect, useState} from 'react';
import {fetchToken,getSteamUri} from '../../actions/watchLiveAction';
import styles from './WatchLiveVideo.module.scss';
import Container from 'react-bootstrap/Container'
import { Navbar} from 'react-bootstrap';
import UpcomingPrograms from '../UpcomingPrograms'
import WeekSchedule from '../WeekSchedule';
import {connect} from 'react-redux';
import { useHomeLiveVideo } from "../../logic/HomeLiveVideo/useHomeLiveVideo";
import VideoPlayer from '../VideoPlayer';
import SecMenu from '../SecMenu';
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../../logic/Dimentions';
import MobileMenu from '../MobileMenu';
import BrowserPersistence from '../../util/simplePersistance';
import {useLocation } from 'react-router-dom';

const storage = new BrowserPersistence();
const WatchLiveVideo = ({fetchToken,getSteamUri}) => {
    const location = useLocation();


    const query = new URLSearchParams(location.search);
    const qRegion = query.get('region')



    const [upComing, setProgram] = useState(true);
    const [region,setRegion] = useState(storage.getItem('CountryCode'));
    const [fullscreen,setFullscreen] = useState(false);
    const [playError,setPlayError] = useState(false);
    const [watchUrl,setWatchUrl] = useState('')
    const logicProps =  useHomeLiveVideo({fetchToken, getSteamUri,region,playError,setPlayError,watchUrl,setWatchUrl})

    const { height, width } = useDimensions();
    useEffect(()=>{
        setRegion(qRegion)
    },[qRegion])


    return(
        <div>
            {width>=992?<SecMenu region={region} setRegion={setRegion}/>:<MobileMenu/>}
            <VideoPlayer url={watchUrl} fullscreen={fullscreen} setFullscreen={setFullscreen} playError={playError} setPlayError={setPlayError} live={true}/>
            {width>=992?            
                <Container>
                        {/* <Navbar> */}
                            {/* <header> */}
                            <div className={styles.head_menu}>
                                <ul className={styles.menu_links}>
                                    <li>
                                        <a data-toggle="tooltip" title="latestVideo" className={upComing?styles.active:null} onClick={() => {setProgram(true);}}>Upcoming Programs</a>
                                    </li>
                                    <li>
                                        <a data-toggle="tooltip" title="relatedVideo" className={!upComing?styles.active:null} onClick={() => {setProgram(false);}}>Full Week Schedule</a>
                                    </li>
                                </ul>
                            </div>
                            {/* </header> */}
                        {/* </Navbar> */}
                </Container>:null}
                {width>=992?upComing?<UpcomingPrograms region={region} setRegion={setRegion}/>:<WeekSchedule style={{ display: upComing ? "block" : "none" }}/>:null}
                
        </div>

    )


}

export default connect(null,{fetchToken,getSteamUri})(WatchLiveVideo);
