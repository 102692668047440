import styles from './NewsDetails.module.scss';
import React,{useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from 'formik';
import StickyNewsHeader from '../StickyNewsHeader';
import {salomapi2} from '../../apis';
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../../logic/Dimentions';



const NewsDetails = ({newsDetails}) => {
    const [isFormSuccess, setFormSuccess] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const { height, width } = useDimensions();
    const blogContent = newsDetails ? newsDetails?.blogContent?.replace(
        /(?:\r\n|\r|\n)/g,
        "<br>"
      ) :null

      const validate = values => {
        const errors = {};
        if (!values.name) {
          errors.name = 'Required';
        } 
        if (!values.description) {
          errors.description = 'Required';
        } 
        return errors;
      };
      const formik = useFormik({
        initialValues: {
            name: '',
            description:''
        },
        validate,
        onSubmit: values => {       
           salomapi2.post('/comments?author_name='+newsDetails.authorName+'&author_comment='+values.name+'&content='+values.description+'&post='+newsDetails.blogId).then( response =>{
               setErrorMsg("")
               setFormSuccess(true)
           })
           .catch(err =>{
            setErrorMsg(err.response.data.message)
           })
        },
      });
    return (
        <div className={styles.detail_page}>
            {width>=992?<StickyNewsHeader/>:null}

            <Container>
                {width>=992?
                <>
                <div className={styles.cover_wrap}>
                <img src={newsDetails ? newsDetails.blogImage:null} />
                </div>
                <div className={styles.blog_intro}>
                    <div className={styles.intro_head}>
                        <p className={styles.blog_cat}>{newsDetails && newsDetails?.category?.length > 0 ? newsDetails?.category[0].category:null}</p>&nbsp;&nbsp;&nbsp;
                        <div className={styles.blog_auth}>
                            <span></span>
                            <p>By
                                <label>
                                    <a href="">{newsDetails ? newsDetails.authorName :null}</a>
                                </label>
                            </p>
                        </div>&nbsp;
                        <p className={styles.blog_date}>{newsDetails ? newsDetails.createdDate:null}</p>&nbsp;
                    </div>
                    <p className={styles.intro_cont}>
                      {newsDetails ? newsDetails.blogTitle:null}
                    </p>
                </div>
                </>:<div className={styles.newMobile}>
                   <div className="news-detail-img">
                    <img src={newsDetails ? newsDetails.blogImage:null} />
                    <span>{newsDetails?.category[0]?.category}</span>
                   </div>
                    <h5>{newsDetails?.blogTitle}</h5>
                    <p>{newsDetails?.createdDate} <label>{newsDetails?.authorName}</label></p>
                    </div>}

                {blogContent  ? (
                
                            <div className={styles.blog_cont} dangerouslySetInnerHTML={{__html: blogContent}}>
                             </div>
                      
                ):null}
              
                <div className={styles.commentBoxBlogWrap}>
                    <h5 className={styles.cmntBox_head}>Responses</h5>
                    <div className={styles.commentBox}>
                            <form className={styles.form_align} onSubmit={formik.handleSubmit}>
                                <Row className={`col-lg-12`}>
                                        <textarea className={styles.textClass} onChange={formik.handleChange} value={formik.values.description}  name="description" placeholder="Leave a message"></textarea>
                                </Row> 
                                <Row className={`col-lg-12`}>
                                        <input type="text" onChange={formik.handleChange} value={formik.values.name}  name="name" placeholder="Please Enter Your Name" required/>
                                </Row> 
                                <Row className={`col-lg-12`}>
                                    <ReCAPTCHA className={styles.captchaClass}
                                    sitekey="Your client site key"
                                    />
                                </Row>  
                                <Row className={`col-lg-12`}>
                                    <button className={styles.coment_sub_btn} type="submit">Submit Comment</button>
                                </Row>  
                                {Object.keys(formik.errors).length!=0 && formik.submitCount ?
                                    <div className="form-group col-sm-12">
                                       <span >Please enter your comment before submit.</span>
                                    </div>:null
                    }   
                               {isFormSuccess?
                                    <div className="form-group col-sm-12">
                                       <span className={styles.resonseSuccessMsg}>Your comment has been submitted for the review</span>
                                    </div>:null
                                }
                                {errorMsg ?
                                    <div className="form-group col-sm-12">
                                       <span className={styles.resonseFailedMsg}>{errorMsg}</span>
                                    </div>:null
                                }

                            </form>
                    </div>
                </div>
            </Container>
        </div>        
    )

}


export default NewsDetails;