import React, { useEffect, useState ,lazy} from "react";
import { fetchSwprayer } from "../../actions/swprayerAction";
import { connect } from "react-redux";
import VideoPlayer from "../VideoPlayer";
import { fetchToken, getMassSteamUri } from "../../actions/watchLiveAction";
import { useSwprayerLiveVideo } from "../../logic/Swprayer/useSwprayerLiveVideo";
import styles from './SwprayerLiveVideo.module.scss';
import SecMenu from '../SecMenu';
import {useDimensions} from '../../logic/Dimentions';
const MobileMenu =  lazy(()=>import('../MobileMenu'));

const SwprayerLiveVideo = ({
  fetchSwprayer,
  swprayer,
  fetchToken,
  getMassSteamUri,
}) => {
  const logicProps = useSwprayerLiveVideo({ fetchToken, getMassSteamUri });
  const { watchUrl } = logicProps;
  const [playError,setPlayError] = useState(false);
  const [region,setRegion] = useState('masslive');
  const { height, width } = useDimensions();

  useEffect(() => {
    if (swprayer == 0) {
      fetchSwprayer();
    }
  }, [swprayer]);

  const [fullscreen, setFullscreen] = useState(false);


  return (
    <div className={styles.swPlayerBanner}>
    
    {width>=992?<SecMenu region={region} setRegion={setRegion}/>:<MobileMenu/>}
    
      <VideoPlayer
        url={watchUrl}
        fullscreen={fullscreen}
        setFullscreen={setFullscreen}
        playError={playError}
        setPlayError={setPlayError}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { swprayer: state.swprayer.swPrayer };
};

export default connect(mapStateToProps, {
  fetchSwprayer,
  fetchToken,
  getMassSteamUri,
})(SwprayerLiveVideo);
