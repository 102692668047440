import React,{useState} from "react";
import styles from "./ProgramThumb.module.scss";
import { useHistory } from "react-router-dom";
import { useDimensions } from "../../logic/Dimentions";
import { isMobile } from "react-device-detect";
import { useViewportAnimation } from "../../logic/ViewportAnimation/useViewportAnimation";

const ProgramThumb = ({ value }) => {
  let history = useHistory();
  const [isLoaded,setLoaded] = useState(false)
  const { height, width } = useDimensions();

  const onClickHandler = () => {
    history.push(`/show/${value.url}`);
  };
  useViewportAnimation();
  const onLoad = () =>{
    setLoaded(true)
}
  return (
    <div
      className={`${styles.ProgrameThumb} col-md-3 col-sm-6 animation`}
      style={{ maxWidth: width > 1200 ? "20%" : width < 992 ? "33%" : null }}
      onClick={onClickHandler}
    >
      
      <figure>
        <img className={isLoaded ? "loaded":null} src={value.image} onLoad={onLoad} />
      </figure>
    </div>
  );
};

export default ProgramThumb;
