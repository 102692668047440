import { combineReducers } from 'redux';

import bannerReducer from './bannerReducer';
import programesReducer from './programesReducer';
import otherdetailsReducer from './otherdetailsReducer';
import latestEpisodesReducer from './latestEpisodesReducer';
import currencyReducer from './currencyReducer';
import footerReducer from './footerReducer';
import categoryReducer from './categoryReducer';
import episodeReducer from './episodeReducer';
import careersReducer from './careersReducer';
import contactusReducer from './contactusReducer';
import menuReducer from './menuReducer';
import newsReducer from './newsReducer'
import aboutUsReducer from './aboutUsReducer'
import LiveScheduleReducer from './LiveScheduleReducer';
import swprayerDetailsReducer from './swprayerDetailsReducer';
import testimoniesReducer from './testimoniesReducer';
import dailymassReducer from './dailymassReducer';
import swprayerReducer from './swprayerReducer';
import secMenuReducer from './secMenuReducer';
import fellowshipReducer from './fellowshipReducer'
import mediagalleryReducer from './mediagalleryReducer'
import watchonReducer from './watchonReducer'
import fullScheduleReducer from './fullScheduleReducer';
import commonSearchReducer from './commonSearchReducer'
import watchLiveImagesReducer from './watchLiveImagesReducer';
import socialLinkReducer from './socialLinkReducer';

export default combineReducers({
    banner:bannerReducer,
    programes:programesReducer,
    otherdetails:otherdetailsReducer,
    latestEpisodes:latestEpisodesReducer,
    currency:currencyReducer,
    footer:footerReducer,
    categories:categoryReducer,
    episodeVideo:episodeReducer,
    careers:careersReducer,
    contactUs:contactusReducer,
    news:newsReducer,
    menu:menuReducer,
    aboutUs:aboutUsReducer,
    liveSchedule:LiveScheduleReducer,
    swprayerDetails:swprayerDetailsReducer,
    testimonies:testimoniesReducer,
    dailymass:dailymassReducer,
    swprayer:swprayerReducer,
    secMenu:secMenuReducer,
    fellowshipData:fellowshipReducer,
    mediaGallery:mediagalleryReducer,
    watchon:watchonReducer,
    fullSchedule:fullScheduleReducer,
    commonSearch:commonSearchReducer,
    watchLiveImages:watchLiveImagesReducer,
    socialLink:socialLinkReducer
}); 