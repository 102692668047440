import React,{useEffect, useState} from 'react'

import styles from './WatchOnSection.module.scss';
import Container from 'react-bootstrap/Container'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ConnectedTvTab from '../ConectedTVTab';
import ChannelFinderTab from '../ChannelFinderTab';
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../../logic/Dimentions';
import { useHistory,useParams } from "react-router-dom";



const WatchOnSection = ({watchonDetails}) => {
    const [value, setValue] = useState(0);
    const { height, width } = useDimensions();
    let history = useHistory();
    let {tab} = useParams()

    useEffect(()=>{
      if(tab==='apps'){
        setValue(1)
      }else if(tab==='channelfinder'){
        setValue(2)
      }else{
        setValue(0)
      }
    },[tab])

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if(newValue==0){
          history.push(`/watchon/connectedtv`);
        }else if(newValue==1){
          history.push(`/watchon/apps`);

        }else if(newValue==2){
          history.push(`/watchon/channelfinder`);

        }
      };
      
      function TabPanel(props) {
        const { children, value, index, ...other } = props;
        
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
             <Box p={3}>
             <Typography>{children}</Typography>
            </Box>
            )}
          </div>
        );
      }

      const onSelectHandler = (e) =>{
        setValue(parseInt(e.target.value));
      }
      
    return(
        <section className={styles.watchon_wrapper}>
            {width>=992?<Container>
             <h2 className={styles.ttlHome }>Watch On</h2>
             <div className={`row ${styles.watchon_tabs}`}>
             <Tabs aria-label="simple tabs example" onChange={handleChange} value={value} className={styles.tabContainer}>
                <Tab label={
                            <a>
                            <span className={`icon-connect_tv ${styles.tv_icon}`}>
                            </span>
                            <p className={styles.letters_hover}>{watchonDetails.tab1 ? watchonDetails.tab1.tabTitle:null}</p>
                            </a>
                           } 
                           className={styles.tab}>
                </Tab>
                <Tab label={
                            <a>
                            <span className={`icon-our_apps ${styles.tv_icon}`}>
                            </span>
                            <p className={styles.letters_hover}>{watchonDetails.tab2 ? watchonDetails.tab2.tabTitle:null}</p>
                            </a>
                           }  
                           className={styles.tab} />
                <Tab label={
                            <a>
                            <span className={`icon-ch-finder ${styles.tv_icon}`}>
                            </span>
                            <p className={styles.letters_hover}>{watchonDetails.tab4 ? watchonDetails.tab4.tabTitle:null}</p>
                            </a>
                           }  
                           className={styles.tab} />
            </Tabs>
             </div>
            </Container>:
            <div className={styles.selWrap}>
<select className={styles.input} onChange={onSelectHandler}>
              <option value={0}>{watchonDetails.tab1 ? watchonDetails.tab1.tabTitle:null}</option>
              <option value={1}>{watchonDetails.tab2 ? watchonDetails.tab2.tabTitle:null}</option>
              <option value={2}>{watchonDetails.tab3 ? watchonDetails.tab3.tabTitle:null}</option>
              </select>
            </div>
            }
            <Container>
            <TabPanel index={0} value={value}>
              <ConnectedTvTab tabType="connectedtv" tabContent={watchonDetails.tab1 ?watchonDetails.tab1.tabContent:null}/>
            </TabPanel>
            <TabPanel index={1} value={value}>
              <ConnectedTvTab tabType="apps" tabContent={watchonDetails.tab2 ? watchonDetails.tab2.tabContent:null}/>
            </TabPanel>
            <TabPanel index={2} value={value}>
              <ChannelFinderTab tabContent={watchonDetails.tab4 ?watchonDetails.tab4.tabContent:null}/>
            </TabPanel>
            </Container>

        </section>      
           
    )
}
export default WatchOnSection;
