import React,{useState,useEffect} from 'react';
import Assets from "../Layouts/Assets";
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../../logic/Dimentions';
import styles from './MobileMenu.module.scss';
import Dropdown from 'react-bootstrap/Dropdown';
import {connect} from 'react-redux';
import {  fetchCommonSearchResult } from "../../actions/menuAction";
import { Link } from "react-router-dom";
import { DiApple } from "react-icons/di";
import { AiFillInstagram,AiFillYoutube,AiFillFacebook } from "react-icons/ai";
import { FaTwitter,FaPinterestP,FaFacebookF} from "react-icons/fa";
import { fetchSecMenu } from "../../actions/menuAction";





const MobileMenu = ({fetchSecMenu,fetchCommonSearchResult,searchResults,secMenu}) => {
const { height, width } = useDimensions();
const [searchOpen,setSearchOpen] = useState(false);
const [searchCat,setSearchCat] = useState('all');
const [searchList, setSearchList] = useState(false);
const [searchBox,setSearchBox] = useState(false);
const [menuOpen,setMenuOpen] = useState(false);

useEffect(() => {
  if(secMenu==0){
      fetchSecMenu();
  }
}, [secMenu]);

const searchByKeyword = (e) => {
    setSearchList(true);
    fetchCommonSearchResult(e.target.value,searchCat)
}

    return (
        <header className={styles.mobHeader}>
            <div className="container">
            <div className={styles.mobHdrWrap}>
                <div className={styles.mobhdrMenu}>
                    <div className={styles.hamburger} onClick={()=>{setMenuOpen(true)}}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className={`${styles.mobileSideNav} ${menuOpen&&styles.open}`}>
                    <div className={styles.menuOverlay} onClick={()=>{setMenuOpen(false)}}></div>
                       <div className={ styles.sideNavWrap}>
                           <div className={styles.logoWrap}>
                                <figure>
                                    <img src={Assets.logos} alt="Shalomworld"/>
                                </figure>
                           </div>
                           <div className={styles.menuTtl}>
                                Menu
                           </div>
                           <ul className={styles.menuList}>
                              {secMenu.map((value,index)=>{
                                return (<li className={styles.menulistItems}>
                                   <Link to={`/${value.slug}`} className={styles.menulistItem} >{value.title}</Link>
                                  {/* <a href="" className={styles.menulistItem}>Daily Mass</a> */}
                              </li>)
                              })

                              }
                                <li className={styles.menulistItems}>
                                <   a href="" className={`${styles.menulistItem} ${styles.getAppLink}`}>Get Shalom World App
                               <span><DiApple></DiApple></span>
                                </a>
                                </li>
                           </ul>
                           <div className={styles.cunnectWith}>
                                <p>Cunnect with us</p>
                                <p>
                                
                                    <a href=""><FaFacebookF></FaFacebookF></a>
                                    <a href=""><FaTwitter></FaTwitter></a>
                                    <a href=""><AiFillInstagram></AiFillInstagram></a>
                                    <a href=""><FaPinterestP></FaPinterestP></a>
                                    <a href=""><AiFillYoutube></AiFillYoutube></a>
                                </p>
                                
                           </div>
                           <div className={styles.bottomTxt}>
                           <p className>2021 Shalom Media</p>
                           </div>
                       </div>
                       
                    </div>
                    
                </div>
                <div className={styles.mobhdrLogo}>
                    <figure>
                        <img src={Assets.logos} alt="Shalomworld"/>
                    </figure>
                </div>
                {/* search section */}
                <div className={styles.mobhdrSearch}>
                     <span class="icon-search" onClick={()=>{
                         setSearchOpen(true)
                         }}> </span>
                     <div className={`${styles.hdrSearchResult} ${searchOpen?styles.open:null}`}>
                        <div className={styles.hdrSearchResultWrap}>
                        <div className={styles.searchBox}>
                          <form action="" className="h-100">
                             <div className={styles.searchBoxRow}>
                                <div className={styles.searchBoxDropdown}>
                                  <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                      All
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item onClick={()=>{setSearchCat('all')}}>All</Dropdown.Item>
                                      <Dropdown.Item onClick={()=>{setSearchCat('programs')}}>Shows</Dropdown.Item>
                                      <Dropdown.Item onClick={()=>{setSearchCat('episodes')}}>Episodes</Dropdown.Item>
                                      <Dropdown.Item onClick={()=>{setSearchCat('blog')}}>News</Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                                <div className={styles.searchBoxform}>
                                  <input className="form-control" type="text" placeholder="Search" onChange={searchByKeyword}/>
                                  {searchResults && searchResults.length > 0 && Array.isArray(searchResults) ?
                                  <div className={styles.searchListResult}>
                                    <ul>
                                          {searchResults && searchResults.length > 0 ?
                                              searchResults?.map((result,index) => {
                                                return (
                                                  <li key={index}>
                                                    <Link to={result.type == 'episodes' ? '/episode/' + result.url : '/show/' + result.url}>
                                                      <div className={styles.imgWrap}>
                                                        <img src={result.image}></img>
                                                      </div>
                                                      <h5 className={styles.textWrap}>
                                                        {result.title}
                                                        <span className={styles.resType}>{result.type == 'episodes' ? 'EPISODES' : 'SHOWS'}</span>
                                                      </h5>
                                                    </Link>
                                                  </li>
                                                )
                                              })
                                              : null
                                            }

                                          </ul>
                                  </div>:null}
                                </div>
                                <div className={styles.searchBoxClose} onClick={() => {
                            setSearchOpen(false);
                          }}>
                                <svg height="48" viewBox="0 0 48 48" width="48" xmlns="http://www.w3.org/2000/svg"><path className="svgclose" d="M38 12.83l-2.83-2.83-11.17 11.17-11.17-11.17-2.83 2.83 11.17 11.17-11.17 11.17 2.83 2.83 11.17-11.17 11.17 11.17 2.83-2.83-11.17-11.17z"/></svg>
                                </div>
                            </div>
                            
                          </form>
                            
                        </div>
                        </div>
                     </div>
                </div>
            </div>
            </div>
            {/* {width>=992?<img src={Assets.logos} style={{width:'25%'}} alt="Shalomworld" />:null}  */}
            

        </header>
    )
}
const mapStateToProps = (state) => {
    return { searchResults: state.commonSearch.searchResult,secMenu: state.secMenu };
  };

export default connect(mapStateToProps,{fetchCommonSearchResult,fetchSecMenu})(MobileMenu);