import React,{useEffect,useState} from 'react';
import {connect} from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown'
import { fetchSecMenu } from "../../actions/menuAction";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import styles from './SecMenu.module.scss';
import clsx from 'clsx';
import { Link,useLocation } from 'react-router-dom';
import _ from 'lodash';



const useStyles = makeStyles({
    list: {
      width: 540,
    },
    fullList: {
      width: 'auto',

    },
  });


const SecMenu = ({fetchSecMenu,secMenu,liveSchedule,region,setRegion}) => {

    useEffect(() => {
        if(secMenu==0){
            fetchSecMenu();
        }
    }, [secMenu]);

    const classes = useStyles();
    const [state, setState] = useState({left: false});
    const location = useLocation();
    console.log(location.pathname.slice(0, location.pathname.lastIndexOf('/')))

    const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, ['left']: open });
  };

    const list = () => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer( false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {secMenu.map((value, index) => (
          <ListItem button key={value.id}>
            <Link to={`/${value.slug}`} >
            <ListItemText primary={value.title} />
            </Link>
          </ListItem>
        ))}
      </List>
    </div>
  );

    return (
        <div className={styles.live_header}>
      <div className={`${styles.hamburger} c0111 c0127`} onClick={toggleDrawer( true)}>
        <span></span>
        <span></span>
        <span></span>
        
      </div>
      {location.pathname.slice(0, location.pathname.lastIndexOf('/'))!='/episode'&&
      <div className={styles.hmDropdown}>
      <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
            <span className={styles.actionPlay}></span> {_.filter(_.toArray(liveSchedule), function (o) {
                return o.slug == region
              })[0]?.caption} ({_.filter(_.toArray(liveSchedule), function (o) {
                return o.slug == region
              })[0]?.name})
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {_.toArray(liveSchedule).map((value,index)=>{
                    return(
                      <Dropdown.Item onClick={()=>{setRegion(value.slug)}} >{value.caption} ({value.name})</Dropdown.Item>
                        // <option value={value.slug}>{value.caption} ({value.name})</option>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
      </div>
      }
      
       <div className={styles.hamburgerMenu}>
       <Drawer anchor={'left'} open={state['left']} className={styles.drawer} onClose={toggleDrawer(false)}>
            {list()}
        </Drawer>
       </div>
    </div>
    )
}

const mapStateToProps = (state) =>{
    return {secMenu: state.secMenu,liveSchedule:state.liveSchedule}
}

export default connect(mapStateToProps,{fetchSecMenu})(SecMenu);