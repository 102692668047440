import {FETCH_MEDIA_GALLERY} from '../actions/types.js';

const INTIAL_STATE = {
    mediaGallery:[]
};
export default (state = INTIAL_STATE,action) => {
    switch(action.type){ 
        case FETCH_MEDIA_GALLERY: 
        return {
            ...state,
            mediaGallery:action.payload
        }
        default:
            return state;
    }
}