import React,{useState,useEffect,lazy, useCallback} from 'react';
import Publication from '../components/Publication';
import Testimonies from '../components/Testimonies';
import DailymassList from '../components/DailymassList';
import SwprayerLiveVideo from '../components/SwprayerLiveVideo';
import SwprayerTab from '../components/SwprayerTab';
import MassPetition from '../components/MassPetition';
import PrayerReqeust from '../components/PrayerReqeust';
import TestimonyRequest from '../components/TestimonyRequest';
import SwprayerAbout from '../components/SwprayerAbout';
import CommingUp from '../components/HomeBanner/CommingUp';
import {fetchOtherdetails} from '../actions/otherdetailsAction';
import {fetchCountryList,fetchStateFromCountry,fetchTimeFromDate} from '../actions/swprayerAction'
import {connect} from 'react-redux';
import { useHome } from '../logic/Home/useHome';
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../logic/Dimentions';
const MainLayout = lazy(()=>import('../components/Layouts/MainLayout'));
const Donate = lazy(()=>import('../components/Donate'));
const RelatedSites = lazy(()=>import('../components/RelatedSites'));
const OtherSection = lazy(()=>import('../components/OtherSection'));
const Subscription = lazy(()=>import('../components/Subscription'));
const Footer = lazy(()=>import('../components/Footer'));
const BottomTab = lazy(()=>import('../components/BottomTab'));


const Swprayer = ({fetchOtherdetails,fetchCountryList,fetchStateFromCountry,fetchTimeFromDate,otherdetails,countryList,stateList,weekTimeList}) => {
    useHome({fetchOtherdetails,otherdetails});
    const [startDate, setStartDate] = useState(null);
    const [countryName, setCountryName] = useState("");
    const [countryInputName, setCountryInputName] = useState("");
    const { height, width } = useDimensions();
    useEffect(()=>{
        // if(fellowship.length ==0)
        fetchCountryList()
    },[])
    const CountryChange = useCallback((event, newValue) => {
       
        if(newValue){
            fetchStateFromCountry(newValue.id)
        }
        
     },[])
     const CountryInputChange = useCallback((event, newInputValue) => {
       
            setCountryInputName(newInputValue);
        
     },[])
     const selectDate = (e) =>{
        setStartDate(e)
        fetchTimeFromDate(e)
      }
    const [value, setValue] = useState(0);
    const renderTab = (value) => {
        switch (value) {
            case 0:
            return <DailymassList/>;
                break;
            case 1:
            return <MassPetition countryList={countryList} stateList={stateList} CountryChange={CountryChange} CountryInputChange={CountryInputChange} selectDate={selectDate} startDate={startDate} weekTimeList={weekTimeList} countryName={countryName} countryInputName={countryInputName}/>;
                break;
            case 2:
            return <PrayerReqeust countryList={countryList} stateList={stateList} CountryChange={CountryChange} CountryInputChange={CountryInputChange} countryName={countryName} countryInputName={countryInputName} />;
                break;
            case 3:
            return <TestimonyRequest countryList={countryList} stateList={stateList} CountryChange={CountryChange} CountryInputChange={CountryInputChange} countryName={countryName} countryInputName={countryInputName} />;
            break;
            case 4:
            return <SwprayerAbout/>;
            break;
        
            default:
            return <MassPetition/>;
                break;
        }
    }
    
    return (
        <>
            <SwprayerLiveVideo/>
            {width>=992?<CommingUp position='inherit'/>:null}
            <SwprayerTab value={value} setValue={setValue}/>
            {renderTab(value)}
            {width>=992?
            <>
            <Publication/>
            <Testimonies/>
            <Donate/>
            <OtherSection/>
            <RelatedSites/>
            <Subscription/>
            <Footer/>
            </>:<BottomTab/>}
        </>

    )
}
const mapStateToProps = (state) => {
    return ({otherdetails:state.otherdetails,countryList:state.swprayer.countryList,stateList:state.swprayer.stateList,weekTimeList:state.swprayer.weekTimeList})
  }
export default connect(mapStateToProps,{fetchOtherdetails,fetchCountryList,fetchStateFromCountry,fetchTimeFromDate})(Swprayer);