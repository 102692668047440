import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import '../styles/_styles.scss';
import { fullPageLoadingIndicator } from './LoadingIndicator';

import homePage from '../pages/homePage';
import Shows from '../pages/Shows'
import Episodes from '../pages/Episodes';
import EpisodeVideo from '../pages/EpisodeVideo';
import Careers from '../pages/Careers';
import ContactUs from '../pages/ContactUs';
import AboutUs from '../pages/AboutUs';
import Swprayer from '../pages/Swprayer';
import Playgournd from '../pages/Playground';
import ShowDetailsPage from '../pages/ShowDetails';

import News from '../pages/News'
import WatchLive from '../pages/WatchLive'
import PeaceFellowship from '../pages/PeaceFellowship';
import MediaGallery from '../pages/MediaGallery';
import Statements from '../pages/Statements';
import TermsandConditions from '../pages/TermsandConditions';
import WatchOn from '../pages/WatchOn';
import Feedback from '../pages/Feedback';
import NewsDetailPage from '../pages/NewsDetailPage';
import Mail from '../pages/Mail';
import ApplyNowPage from '../pages/ApplyNowPage';
import SocialMediaWrap from '../components/SocailMediaIcons'
import ScrollToTop from './ScrollToTop';

const App = () => {


  return (
    <Router basename={'/'}>
      <div>
        <Suspense fallback={fullPageLoadingIndicator}>
      <ScrollToTop/>
          <Switch>
            <Route exact path='/' component={homePage}></Route>
            <Route exact path='/shows' component={Shows}></Route>
            <Route exact path='/shows/:category' component={Shows}></Route>
            <Route exact path='/show/:showId' component={ShowDetailsPage}></Route>
            <Route exact path='/episodes' component={Episodes}></Route>
            <Route exact path='/episodes/:category' component={Episodes}></Route>
            <Route path='/episode/:episodeId' component={EpisodeVideo}></Route>
            <Route exact path='/career' component={Careers}></Route>
            <Route exact path='/career/:careerId' component={ApplyNowPage}></Route>
            <Route exact path='/contact/' component={ContactUs}></Route>
            <Route exact path='/about' component={AboutUs}></Route>
            <Route exact path='/news' component={News}></Route>
            <Route path='/news/:newsId' component={NewsDetailPage}></Route>
            <Route exact path='/watchlive' component={WatchLive}></Route>
            <Route exact path='/peacefellowship' component={PeaceFellowship}></Route>
            <Route exact path='/peacefellowship/mail' component={Mail}></Route>

            <Route exact path='/mediagallery' component={MediaGallery}></Route>

            <Route exact path='/news' component={News}></Route>
            <Route exact path='/swprayer' component={Swprayer}></Route>
            <Route exact path='/playground' component={Playgournd}></Route>
            <Route exact path='/privacystatement' component={Statements}></Route>
            <Route exact path='/termsandconditions' component={TermsandConditions}></Route>
            <Route exact path='/watchon' component={WatchOn}></Route>
            {/* <Route path='/watchon/:tab' component={WatchOn}></Route> */}
            <Route path='/watchon/:tab/:platform?' component={WatchOn}></Route>
            <Route exact path='/feedback' component={Feedback}></Route>

          </Switch>
          <SocialMediaWrap />
        </Suspense>
      </div>
    </Router>
  )
}

// const mapStateToProps = (state) => {
//   return ({otherdetails:state.otherdetails}) 
// }

// export default connect(mapStateToProps,{fetchOtherdetails})(App);

export default App;