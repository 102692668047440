import React, { useEffect } from 'react'
import ReactPlayer from 'react-player/lazy'
import styles from './AboutUs.module.scss';
import Container from 'react-bootstrap/Container'
import {fetchAboutus} from '../../actions/aboutusAction'
import {connect} from 'react-redux';
import Endorsements from '../Endorsements';

const AboutUs = ({fetchAboutus,aboutUs}) => {
    useEffect(()=>{
        if(aboutUs.length ==0)
        fetchAboutus()
    },[fetchAboutus])
    return(
        <div >
            <div className={styles.video_align}>
                <div className="vdoWrap wide">
                    {aboutUs.bannerVideo && aboutUs.bannerVideo.length > 0 ? (<ReactPlayer url={aboutUs.bannerVideo[0].url} 
                    width='100%'
                    height='100%'
                    controls = {true}/>):null}
                </div>
            </div>
            <Container>
                <h2 className={styles.is_title}>{aboutUs.aboutHeading}</h2>
                <p className={styles.para_align}>
                   {aboutUs.Description}
                </p>
            </Container>
               {aboutUs.endorsement ? (<Endorsements endorsments={aboutUs.endorsement.endorsements} />):null} 
           
        </div>
    )
}
const mapPropsToState = (state) => {
     return {aboutUs:state.aboutUs.aboutUs}
}
export default connect(mapPropsToState,{fetchAboutus})(AboutUs);

