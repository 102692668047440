import React from 'react';
import styles from './NewsCategory.module.scss';

const NewsCategory = ({value}) => {
    return (
        <div className="news-tab-wrp">
        <ul className={styles.newsCategory}>
            {value.map((cat)=>{
                return (
                    <li>{cat.title}</li>
                )
            })}
        </ul>
        </div>
    )
}

export default NewsCategory;