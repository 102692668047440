import React,{useState,useCallback} from 'react'
import styles from './Endorsements.module.scss';
import Container from 'react-bootstrap/Container'
import { Navbar} from 'react-bootstrap';
import {fetchMoreEndorsements} from '../../actions/aboutusAction'
import Row from 'react-bootstrap/Row';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {connect} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import InfiniteScroll from 'react-infinite-scroller';

const Endorsements = ({fetchMoreEndorsements,moreEndoresments,maxPage,currentPage,endorsments}) => {
    const [value, setValue] = useState(0);
    let loadPageAll,loadPageAmerica,loadPageAsia,loadPageAustralia,loadPageCanada,loadPageIre,loadPageUk
    loadPageAll = loadPageAmerica = loadPageAsia = loadPageAustralia = loadPageCanada = loadPageIre = loadPageUk = 1
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
     
      const loadEndorsements = useCallback((endorseList) =>{
        let page
        switch(endorseList.slug){
            case 'all' : loadPageAll++ ;
                         page = loadPageAll
                         break
            case 'america' : loadPageAmerica++;
                            page = loadPageAmerica
                                break;
            case 'asia' : loadPageAsia++;
                            page = loadPageAsia
                            break;
            case 'australia' : loadPageAustralia ++
                                page = loadPageAustralia
                                break;
            case 'canada' : loadPageCanada++;
                            page = loadPageCanada
                            break;
            case 'ireland' : loadPageIre++;
                             page = loadPageIre
                                break;
            case 'uk'   : loadPageUk++;
                            page = loadPageUk
                            break;
            default:   break;
            
        }
        fetchMoreEndorsements(endorseList.slug,page,endorseList.endorsement)
    },[])
      function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
      const loader = <div className="loader">Loading ...</div>;
     const EndorsementItem = ({item}) =>{
         return(
           
             item && item.length>0 ? (
                    item.map((endorse) =>{
                        return(
                          
                            <div className="col-lg-4 col-xs-6">
                       
                            <div className={styles.img_wrap}>
                                <img src={endorse.image} className={styles.img_tag}></img>
                                <span></span>
                            </div>
                            <div className={styles.content_wrap}>
                                <div className={styles.quote_box}>
                                    <q>{endorse.quote}</q>
                                </div>
                                <h4>{endorse.name}</h4>
                                <p>{endorse.designation}</p>
                            </div>
                            <a href="" className={styles.full_link}></a>
                        </div>
                       
                        )
                    
                    })
                
                ):null
             
               
         )
     }
    return(
        <div >
            <Container>
                <h2 className={styles.is_title}>Endorsements</h2>
                <Navbar className={styles.Menu}>
                    <section className="endorsement-nav">
                   
                    <div className={styles.head_menu}>
                    <Tabs value={value} onChange={handleChange}  aria-label="simple tabs example">
                   
                   <Tab label={endorsments.all.title}  />
                   <Tab label={endorsments.america.title}  />
                   <Tab label={endorsments.asia.title}  />
                   <Tab label={endorsments.australia.title}  />
                   <Tab label={endorsments.canada.title}  />
                   <Tab label={endorsments.ireland.title}  />
                   <Tab label={endorsments.uk.title}  />
              </Tabs>
                        {/* <ul className={styles.menu_links}>
                     
                            <li><a>All</a></li>
                            <li><a>America</a></li>
                            <li><a>Asia</a></li>
                            <li><a>Australia</a></li>
                            <li><a>Canada</a></li>
                        </ul> */}
                    </div>
                    
                    </section>
                </Navbar>
                <TabPanel value={value} index={0}>
                <InfiniteScroll
                            pageStart={1}
                            loadMore={() =>loadEndorsements(endorsments.all)}
                            hasMore={currentPage<maxPage || maxPage==1 ? true:false}
                            loader={loader}
                            >
                <Row className={styles.row_wrap}>
               {moreEndoresments.length>0 ? (<EndorsementItem item={moreEndoresments} />):<EndorsementItem item={endorsments.all.endorsement} />}
                </Row>
                </InfiniteScroll>
                </TabPanel>
                <TabPanel value={value} index={1}>
                <InfiniteScroll
                            pageStart={1}
                            loadMore={() => loadEndorsements(endorsments.america)}
                            hasMore={currentPage<maxPage || maxPage==1 ? true:false}
                            loader={loader}
                            >
                <Row className={styles.row_wrap}>
                {moreEndoresments.length>0 ? (<EndorsementItem item={moreEndoresments} />):<EndorsementItem item={endorsments.america.endorsement} />}
                </Row>
                </InfiniteScroll>
                </TabPanel>
                <TabPanel value={value} index={2}>
                <InfiniteScroll
                            pageStart={1}
                            loadMore={()=> loadEndorsements(endorsments.asia)}
                            hasMore={currentPage<maxPage || maxPage==1 ? true:false}
                            loader={loader}
                            >
                <Row className={styles.row_wrap}>
                {moreEndoresments.length>0 ? (<EndorsementItem item={moreEndoresments} />):<EndorsementItem item={endorsments.asia.endorsement} />}
                </Row>
                </InfiniteScroll>
                </TabPanel>
                <TabPanel value={value} index={3}>
                <InfiniteScroll
                            pageStart={1}
                            loadMore={() =>loadEndorsements(endorsments.australia)}
                            hasMore={currentPage<maxPage || maxPage==1 ? true:false}
                            loader={loader}
                            >
                <Row className={styles.row_wrap}>
                {moreEndoresments.length>0 ? (<EndorsementItem item={moreEndoresments} />):<EndorsementItem item={endorsments.australia.endorsement} />}
                </Row>
                </InfiniteScroll>
                </TabPanel>
                <TabPanel value={value} index={4}>
                <InfiniteScroll
                            pageStart={1}
                            loadMore={() =>loadEndorsements(endorsments.canada)}
                            hasMore={currentPage<maxPage || maxPage==1 ? true:false}
                            loader={loader}
                            >
                <Row className={styles.row_wrap}>
                {moreEndoresments.length>0 ? (<EndorsementItem item={moreEndoresments} />):<EndorsementItem item={endorsments.canada.endorsement} />}
                </Row>
                </InfiniteScroll>
                </TabPanel>
                <TabPanel value={value} index={5}>
                <InfiniteScroll
                            pageStart={1}
                            loadMore={() => loadEndorsements(endorsments.ireland)}
                            hasMore={currentPage<maxPage || maxPage==1 ? true:false}
                            loader={loader}
                            >
                <Row className={styles.row_wrap}>
                {moreEndoresments.length>0 ? (<EndorsementItem item={moreEndoresments} />):<EndorsementItem item={endorsments.ireland.endorsement} />}
                </Row>
                </InfiniteScroll>
                </TabPanel>
                <TabPanel value={value} index={6}>
                <InfiniteScroll
                            pageStart={1}
                            loadMore={()=>loadEndorsements(endorsments.uk)}
                            hasMore={currentPage<maxPage || maxPage==1 ? true:false}
                            loader={loader}
                            >
                <Row className={styles.row_wrap}>
                {moreEndoresments.length>0 ? (<EndorsementItem item={moreEndoresments} />):<EndorsementItem item={endorsments.uk.endorsement} />}
                </Row>
                </InfiniteScroll>
                </TabPanel>
            </Container>
        </div>
    )
}
const mapPropsToState = (state) => {
    return {moreEndoresments:state.aboutUs.moreEndoresments,maxPage:state.aboutUs.maxPage,currentPage:state.aboutUs.page}
}
export default connect(mapPropsToState,{fetchMoreEndorsements})(Endorsements);
