import React from 'react';
import CommonLayout from '../components/Layouts/CommonLayout';
import { useHome } from '../logic/Home/useHome';
import MediaGallery from '../components/MediaGallery';
import {connect} from 'react-redux';
import {fetchOtherdetails} from '../actions/otherdetailsAction';

const Media = ({fetchOtherdetails,otherdetails}) => {
    useHome({fetchOtherdetails,otherdetails});
    return (
        <CommonLayout>
        <MediaGallery/>
        </CommonLayout>
        
    )
}
const mapStateToProps = (state) => {
  return ({otherdetails:state.otherdetails})
}
export default connect(mapStateToProps,{fetchOtherdetails})(Media);