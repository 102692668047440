import styles from './SignInTab.module.scss';
import React,{useState} from 'react';
import Row from 'react-bootstrap/Row';
import { useFormik } from 'formik';
import ReCAPTCHA from "react-google-recaptcha";
import {salomapi3} from '../../apis';

const SignInTab = () => {
    const [isFormSuccess, setFormSuccess] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const validate = values => {
        const errors = {};
        if (!values.username) {
          errors.username = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.username)) {
          errors.username = 'Invalid email address';
        }
        if (!values.password) {
          errors.password = 'Required';
        } 
        return errors;
      };
    const formik = useFormik({
        initialValues: {
            username: '',
        },
        validate,
        onSubmit: values => {
           const postData = {
            email    :values.username,
            password:values.password
           }
          
           salomapi3.post('/medialogin',postData).then( response =>{
               setErrorMsg("")
               setFormSuccess(true)
              
           })
           .catch(err =>{
            setErrorMsg(err.response.data.message)
           })
          //  alert(JSON.stringify(values, null, 2));
        },
      });
    return (
        <div className={styles.gallery_signup}>
            <h3>Get Started Now</h3>
           
                <form className={styles.form_align} onSubmit={formik.handleSubmit}>
                    <Row>
                        <div className={styles.textClass}>User Name :
                            <input type="text" onChange={formik.handleChange} value={formik.values.username}  name="username" placeholder="Enter your user name"></input>
                        </div>
                    </Row>
                    <Row>
                        <div className={styles.textClass}>Password :
                            <input type="text" onChange={formik.handleChange} value={formik.values.password} name="password" placeholder="Enter your password"></input>
                        </div>
                    </Row> 
                    <Row>
                        <span className={styles.fg_pw_lnk}>Forgot password?</span>
                    </Row>
                    <Row>
                        <ReCAPTCHA className={styles.captchaClass}
                        sitekey="Your client site key"
                        />
                    </Row>  
                    <button type="submit" className={styles.signup_btn}>SUBMIT</button>
                    {Object.keys(formik.errors).length!=0 && formik.submitCount ?
                                    <div className="form-group col-sm-12">
                                       <span className={styles.formtotNullfieldErr}>Please fill all required fields.</span>
                                    </div>:null
                    }
                    {isFormSuccess?
                                    <div className="form-group col-sm-12">
                                       <span className={styles.resonseSuccessMsg}>Your account logged in successfully!!!</span>
                                    </div>:null
                                }
                    {errorMsg ?
                                    <div className="form-group col-sm-12">
                                       <span className={styles.resonseFailedMsg}>{errorMsg}</span>
                                    </div>:null
                                }

                </form>
                
           
        </div>
        
    )

}

export default SignInTab;
