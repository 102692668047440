import React,{useState} from 'react';
import Button from 'react-bootstrap/Button';
import styles from './ChannelFinderTab.module.scss';
import WatchonFormMail from "../WatchonFormMail"
import WatchonFormRequest from '../WatchonFormRequest';


const ChannelFinderAreaList = ({output,zipCode}) => {

    const [sendEmail,setSendEmail] = useState(false);
    const [selectedChannel,setSelectedChannel] = useState(false);
    
    return (
    output?.status=='error'?
            <div className={styles.nochannel}>
                <p className={styles.message}>{output.msg}</p>
                <h2 className="styles.ttlHome">Request The Shalom World TV Network today!</h2>
                <div className={`row ${styles.buttonContainer}`}>
                <div className={`col-md-3 ${styles.buttonInner}`}>
                    <Button  variant="outline-primary" size="lg" onClick={()=>{setSendEmail(true)}}>SEND EMAIL</Button>
                </div>
                <div className={`col-md-3 ${styles.buttonInner}`}>
                    <Button  variant="outline-primary" size="lg">SEND TWEET</Button>
                </div>
                </div>
                {sendEmail&&<WatchonFormMail/>}
            </div>:<div className={styles.channelList}>
                <h2 className="styles.ttlhome">Request Shalom World from your provider.</h2>
                {output?.channels.map((value)=>{
                    return (
                    <div className={`${styles.listContainer}`}>
                        <div className={`${styles.channelName}`}>{value.provider}</div>
                        <div className={styles.channelBtn}>
                            <div className={` ${styles.buttonInner}`}>
                                <Button  variant="outline-primary" size="lg" onClick={()=>setSelectedChannel(value)}>REQUEST</Button>
                            </div>
                            <div className={`${styles.buttonInner}`}>
                                <Button  variant="outline-primary" size="lg">SEND TWEET</Button>
                            </div>
                        </div>
                    </div>
                    )
                })}
                {selectedChannel!=false&&<WatchonFormRequest zipCode={zipCode} channel={selectedChannel} />}
            </div>
        )
    }



export default ChannelFinderAreaList;