import React,{useEffect,useCallback,useState} from 'react';
import MainLayout from '../components/Layouts/MainLayout';
import StickyNewsHeader from '../components/StickyNewsHeader';
import BlogTitleBanner from '../components/BlogTitleBanner'
import BlogList from '../components/BlogList'
import {fetchBlogList} from '../actions/newsAction';
import {connect} from 'react-redux';
import styles from './News.module.scss';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';

const News = ({fetchBlogList,news}) => {
    // const [loadPage,setloadPage] = useState(1)
    let blogs
    let loadPage = 1 
    useEffect(()=>{
        if(news.length===0)
        fetchBlogList(1);
        
    },[])
    
    const loadMorePage = useCallback(() =>{
        loadPage++
        fetchBlogList(loadPage);
    },[])
    if(news.length >0)
    blogs = news.slice(1,news.length)
    return (
        <MainLayout>
           
     <StickyNewsHeader className={styles.margin_wrap} />
     <div className={styles.blogpgwrap}>
    
         {news.length > 0 ? (
              <div className={styles.blog_wrapper}>
             <BlogTitleBanner newsHead={news[0]} />
             <BlogList loadMorePage={loadMorePage} blogList={blogs} />
             </div>
         ):null}
     
     
     </div>
        </MainLayout>
    )
}
const mapStateToProps = (state) =>{
    return ({news:state.news.blogList})
    }
export default connect(mapStateToProps,{fetchBlogList})(News);