import styles from './CategoryList.module.scss';
import { Navbar} from 'react-bootstrap';
import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux';
import {fetchCategories} from '../../actions/categoryAction';
import {useCategoryList} from "../../logic/CategoryList/useCategoryList";
import { Link,useHistory } from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../../logic/Dimentions';

const CategoryList = ({fetchCategories,categories,SetCatgeory}) => {
    const { height, width } = useDimensions();
    const {location} = useHistory();
    let path = location.pathname;
    let pageName = 'shows';
    if(path.search('/episodes')!==-1){
        pageName = 'episodes';
    }

    const [stickyMenu1,setStickyMenu1] = useState(false)
    const [limitCategory,setLimitedCategory] = useState([]);
    const [dropDownCategory,setDropDownCategory] = useState([]);


    useEffect(()=>{
        window.addEventListener('scroll', handleScroll);
    },[])

    useEffect(()=>{
        if(categories.length>0&&limitCategory.length==0){
            if(width<363){
                setLimitedCategory([])
                setDropDownCategory(categories)
            }else if(width<493){
                setLimitedCategory(categories.slice(0,1))
                setDropDownCategory(categories.slice(1,categories.length))
            }else if(width<593){
                setLimitedCategory(categories.slice(0,2))
                setDropDownCategory(categories.slice(2,categories.length))
            }else if(width<723){
                setLimitedCategory(categories.slice(0,3))
                setDropDownCategory(categories.slice(3,categories.length))
            }else if(width<899){
                setLimitedCategory(categories.slice(0,4))
                setDropDownCategory(categories.slice(4,categories.length))
            }else if(width<1002){
                setLimitedCategory(categories.slice(0,5))
                setDropDownCategory(categories.slice(5,categories.length))
            }else if(width<1118){
                setLimitedCategory(categories.slice(0,6))
                setDropDownCategory(categories.slice(6,categories.length))
            }else if(width<1212){
                setLimitedCategory(categories.slice(0,7))
                setDropDownCategory(categories.slice(7,categories.length))
            }else if(width>1212){
                setLimitedCategory(categories.slice(0,8))
                setDropDownCategory(categories.slice(8,categories.length))
            }
        }
    },[categories])

    useEffect(()=>{
        if(width<363){
            setLimitedCategory([])
            setDropDownCategory(categories)
        }else if(width<493){
            setLimitedCategory(categories.slice(0,1))
            setDropDownCategory(categories.slice(1,categories.length))
        }else if(width<593){
            setLimitedCategory(categories.slice(0,2))
            setDropDownCategory(categories.slice(2,categories.length))
        }else if(width<723){
            setLimitedCategory(categories.slice(0,3))
            setDropDownCategory(categories.slice(3,categories.length))
        }else if(width<899){
            setLimitedCategory(categories.slice(0,4))
            setDropDownCategory(categories.slice(4,categories.length))
        }else if(width<1002){
            setLimitedCategory(categories.slice(0,5))
            setDropDownCategory(categories.slice(5,categories.length))
        }else if(width<1118){
            setLimitedCategory(categories.slice(0,6))
            setDropDownCategory(categories.slice(6,categories.length))
        }else if(width<1212){
            setLimitedCategory(categories.slice(0,7))
            setDropDownCategory(categories.slice(7,categories.length))
        }
    },[width])

    

    const handleScroll = (e) => {
        
           if(window.pageYOffset>200){
            setStickyMenu1(true)
          }else{
            setStickyMenu1(false)
          }
    }

    <style>
        
    </style>

   useCategoryList({fetchCategories,categories})

   const Submenu = (
    <ul className={styles.nav__submenu}>
        {
            dropDownCategory ?  dropDownCategory.map((category,index) =>{
                return(
                    <li className={styles.nav__submenu_item } key={index}>
                    <Link to={`/${pageName}/${category.slug}`} >{category.title}</Link>
                  </li>
                )
            }):null
        }
  </ul>
   )
    return(
        <Navbar className={styles.Menu}>
            <header className={styles.MenuList}>
                <div className={`container ${limitCategory.length==0 ? 'headmenuload':''}`}>
                    <div className={styles.head_menu}>
                        <ul className={styles.menu_links,stickyMenu1?styles.stickMenu:null}>   
                            {limitCategory?limitCategory.map((value,index)=>{  
                            return(
                                <li key={index}> 
                                    <Link to={`/${pageName}/${value.slug}`}>{value.title}</Link>
                                </li>
                                )}):null}
                                <li className={styles.nav__menu_item}> <a data-toggle="tooltip"title="More" > More </a> {Submenu}</li>
                        </ul>
                    </div>
                </div>
            </header>
        </Navbar>
      )
  }

const mapStateToProps = (state) =>{
return ({categories:state.categories})
}

export default connect(mapStateToProps,{fetchCategories})(CategoryList);