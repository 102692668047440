import {FETCH_SWPRAYER,FETCH_COUNTRY_LIST,FETCH_STATE_FROM_COUNTRY,FETCH_TIME_FROM_DATE} from '../actions/types';

const INTIAL_STATE = {
    swPrayer:[],
    countryList:[],
    stateList:[],
    weekTimeList:[]
};

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_SWPRAYER:
            return {
                ...state,
                swPrayer: action.payload
            }
        case FETCH_COUNTRY_LIST:
            return {
                ...state,
                countryList:action.payload
            }
        case FETCH_STATE_FROM_COUNTRY:
                return {
                    ...state,
                    stateList:action.payload
            }
        case FETCH_TIME_FROM_DATE:
                return {
                    ...state,
                    weekTimeList:action.payload
            }
        default:
            return state;
    }
}