import React,{useState} from 'react';
import { Formik, Field, ErrorMessage,useFormik } from 'formik';
import {Form,Row,Col} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import styles from './TestimonyRequest.module.scss';
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReCAPTCHA from "react-google-recaptcha";
import salomapi from '../../apis';


const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      color: "white",
    }
  }
}));

const TestimonyRequest = ({countryList,CountryInputChange,stateList,CountryChange,countryName,countryInputName}) => {
  const [state, setState] = useState("");
  const classes = useStyles();
  const StateChange = (event, newValue) => {
    setState(newValue)
   // setValue(newValue);
  }
  const validate = values => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = 'Required';
    } else if (values.firstName.length > 15) {
      errors.firstName = 'Must be 15 characters or less';
    }
  
    if (!values.lastName) {
      errors.lastName = 'Required';
    } else if (values.lastName.length > 20) {
      errors.lastName = 'Must be 20 characters or less';
    }
  
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (countryInputName =="") {
      errors.country = 'Required';
    } 
    if (!values.massPetitionDesc) {
      errors.massPetitionDesc = 'Required';
    } 
    if (!values.address) {
      errors.address = 'Required';
    } 
    if (!values.city) {
      errors.city = 'Required';
    } 
    if (!values.postCode) {
      errors.postCode = 'Required';
    } 
    if(!state) {
      errors.state = 'Required';
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validate,
    onSubmit: values => {
       const postData = {
        firstname : values.firstName,
        lastname : values.lastName,
        address  :values.address,
        email    :values.email,
        city :values.city,
        state: state,
        zipcode:values.postCode,
        country:countryInputName,
        testimony:values.massPetitionDesc,
        testimony_permission:1,

       }
       salomapi.post('/swprayer/testimonyrequest',postData).then( response =>{
       })
      //  alert(JSON.stringify(values, null, 2));
    },
  });
       return (
        <div className={styles.TestimonyRequest}>
        <Container >
            {/* <h4>Mass description goes here</h4> */}
            <p className={styles.testimony_para}>
              <i>“I will praise you to all my brothers; I will stand up before the congregation and testify of the wonderful things you have done.” (Psalm 22:22) </i>
            </p>
            <p className={styles.testimony_para}>Share testimonies of answered prayer, SW PRAYER’s impact on your life or that of a loved one. Your personal stories testify to the power of God in your life and bless all those who read them.</p>
            {/* <Formik

initialValues={{ email: '', password: '' }}
validate={values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address';
  }

  return errors;

}}

onSubmit={(values, { setSubmitting }) => {

  setTimeout(() => {

    alert(JSON.stringify(values, null, 2));

    setSubmitting(false);

  }, 400);

}}

>

{({ isSubmitting }) => ( */}

  {/* // <Form>

  //   <Field type="email" name="email" />

  //   <ErrorMessage name="email" component="div" />

  //   <Field type="password" name="password" />

  //   <ErrorMessage name="password" component="div" />

  //   <button type="submit" disabled={isSubmitting}>

  //     Submit

  //   </button>

  // </Form> */}

  <Form className={styles.form} onSubmit={formik.handleSubmit}>
  <Row>
    <Col>
      <input placeholder="First name *" onChange={formik.handleChange} value={formik.values.firstName} name="firstName" className={!formik.errors.firstName || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact}></input>
    </Col>
    <Col>
    <input placeholder="Last name *" onChange={formik.handleChange} value={formik.values.lastName} name="lastName" className={!formik.errors.lastName || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact }></input>
    </Col>
  </Row>
  <Row>
    <Col>
    <input placeholder="Email *" onChange={formik.handleChange} value={formik.values.email}  name="email" className={!formik.errors.email || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact}></input>
    </Col>
    <Col>
    <Autocomplete
     
     id="combo-box-demo"
     classes={classes}
     options={countryList}
     getOptionLabel={(option) => option.country_name}
     renderOption={(option) => (
       <div style={{ color: "white" }}>{option.country_name}</div>
     )}
     style={{ width: 404 }}
     inputValue={countryInputName}
     onInputChange={CountryInputChange}
     onChange={CountryChange}
     renderInput={(params) => <TextField className={!formik.errors.country || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact} {...params} placeholder="Select Country" name="country" variant="outlined" />}
   />
    {/* <input placeholder="Select Country *" className={styles.input}></input> */}
    </Col>
  </Row>
  <Row>
    <Col>
    <input placeholder="Address" onChange={formik.handleChange} value={formik.values.address} name="address" className={!formik.errors.address || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact}></input>
    </Col>
    <Col>
    <input placeholder="City" onChange={formik.handleChange} value={formik.values.city} name="city" className={!formik.errors.city || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact}></input>
    </Col>
  </Row>
  <Row>
    <Col>
    <Autocomplete
     
     id="combo-box-demo"
     classes={classes}
     options={stateList.length> 0 ? stateList :countryList}
     getOptionLabel={(option) => stateList.length> 0 ? option.name:option.country_name}
     renderOption={(option) => (
       <div style={{ color: "white" }}>{stateList.length> 0 ? option.name:option.country_name}</div>
     )}
     style={{ width: 404 }}
     inputValue={state}
     onInputChange={StateChange}
     renderInput={(params) => <TextField  className={!formik.errors.state || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact} {...params} placeholder="Select State/ Province" variant="outlined" />}
   />
    {/* <input placeholder="Select state / Province" className={styles.input}></input> */}
    </Col>
    <Col>
    <input placeholder="Postal Code" onChange={formik.handleChange} value={formik.values.postCode} name="postCode" className={!formik.errors.postCode || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact}></input>
    </Col>
  </Row>
  <Row>
    <Col>
      <textarea placeholder="Testimony *" onChange={formik.handleChange} value={formik.values.massPetitionDesc} className={!formik.errors.massPetitionDesc || !formik.submitCount ? styles.textarea : styles.textarea +" "+styles.formErrReact} name="massPetitionDesc">
      </textarea>
      <p className={styles.textarea_para}> characters {formik.values.massPetitionDesc ? formik.values.massPetitionDesc.length : 0} / 250</p>
      {formik.errors.massPetitionDesc ? <div>{formik.errors.massPetitionDesc}</div> : null}
    </Col>
  </Row>
  <Row>
    <Col>
      <input type="file" name="photo" accept=".png, .jpg, .PNG, .JPG" placeholder="Upload photo" className={styles.uploadbtn}>
      </input>
      <label for="apply-photo">
        <h6 className={styles.photoLbl}>Upload photo</h6>
      </label>
    </Col>
  </Row>
  <Row className="col-lg-12 chckbx-wrp">
     <input type="checkbox" id="chkbx2" className={styles.chkbox}/>
     <label className={styles.label_txt} for="chkbx2">I give permission to publish the photo and testimony in the website</label>
  </Row>  
  <Row className={styles.div_fields}>
      <ReCAPTCHA className={styles.captchaClass}
      sitekey="Your client site key"
      />
  </Row>  
  <Row>
    <Col>
      <button className={styles.prayer_request_btn}>SUBMIT</button>
    </Col>
  </Row>
</Form>

{/* // )}

// </Formik> */}
        </Container>
        </div>
    )
}

export default TestimonyRequest;