import styles from './Episodes.module.scss';
import CategoryList from '../CategoryList';
import React,{useCallback, useEffect,useState} from 'react';
import {connect} from 'react-redux';
import {fetchCategoryEpisode,fetchLatestEpisode} from '../../actions/episodesAction';
import {removeEpisodeVideo} from '../../actions/episodeVideoAction';
import Container from 'react-bootstrap/Container';
import EpisodeList from '../EpisodeList';
import CountryDropDown from  '../CountryDropdown'
import InfiniteScroll from 'react-infinite-scroller';
import {useLatestEpisodes} from '../../logic/LatestEpisodes/useLatestEpisodes';
import { useParams,useHistory } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../../logic/Dimentions';



const Episodes = ({fetchCategoryEpisode,fetchLatestEpisode,removeEpisodeVideo,latestEpisodes,categories}) => {


        const {location} = useHistory();
        let path = location.pathname;
        let {category} = useParams()
        const { height, width } = useDimensions();

        
        useEffect(()=>{
            removeEpisodeVideo()
            
            // fetchEpisodeVideo(showId).then((value)=>{
            //     setProgramDetails(value)
                
            // }).catch(err=>console.log(err))
        },[])

        useLatestEpisodes({fetchCategoryEpisode,fetchLatestEpisode,latestEpisodes,category,categories,path})

    // const [hasMoreItems,setMoreItems] = useState(true)
    // const [country, setCountry] = React.useState('');
    // let loadPage = 1
    // let categoryData = 'popular'
    // let regionData = 'all'
    // useEffect(()=>{
    //     if(latestEpisodes.length===0)
    //     fetchCategoryEpisode('popular',1,'all')
    // },[])
    // const selectCategory = useCallback((category) => {
    //     categoryData = category
    //     fetchCategoryEpisode(category,1,regionData)
    // },[])
    // const selectCountry = useCallback((event) => {
    //     regionData = event.target.value
    //     setCountry(event.target.value);
    //     fetchCategoryEpisode(categoryData,1,regionData)
    // },[])
    // const loadItems = useCallback(() =>{
    //     loadPage++
    //     fetchCategoryEpisode(categoryData,loadPage,regionData)
    //     // setMoreItems(false)
    // },[])
    if(path=='/episodes'){
        category = 'popular' 
    }
    const loader = <div className="loader">Loading ...</div>;
    return (
        <div className={styles.headingAlign}>
            <Container>
                {width>=992?<h1 className={styles.ttlHome}>Episodes</h1>:null}
                {/* <CountryDropDown selectCountry={selectCountry} country={country} /> */}
                <CategoryList />
                {/* <InfiniteScroll
                pageStart={1}
                loadMore={loadItems}
                hasMore={true}
                loader={loader}
                > */}
                <EpisodeList data={latestEpisodes[category]}/>
                {/* </InfiniteScroll> */}
            </Container>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {latestEpisodes:state.latestEpisodes,categories:state.categories}
}

export default connect(mapStateToProps,{fetchCategoryEpisode,fetchLatestEpisode,removeEpisodeVideo})(Episodes);