import {FETCH_BLOG_CATEGORIES,FETCH_BLOG_LIST,FETCH_NEWS_DESCRIPTION} from '../actions/types.js';

const INTIAL_STATE = {
  blogCategories :[],
  blogList :[]
};

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_BLOG_CATEGORIES:
            return {
                ...state,
                blogCategories :action.payload
            }
        case FETCH_BLOG_LIST:
            return {
                ...state,
                blogList :state.blogList.concat(action.payload)
            }
            case FETCH_NEWS_DESCRIPTION:
                return {
                    ...state,
                    newsDescription :action.payload
                }
        default:
            return state;
    }
}