import { useEffect } from 'react';
export const useLatestEpisodes = props => { 
    const {fetchCategoryEpisode,fetchLatestEpisode,latestEpisodes,category,categories,path} =props
    useEffect(()=>{
        if(path=='/'){
            if(latestEpisodes?.length===0)
            fetchLatestEpisode()
        }else{
            let cat=category;
            if(path=='/episodes'){
                cat='popular';
            }

            if(latestEpisodes[cat]==undefined){
            //     fetchCategoryEpisode(cat)
                fetchCategoryEpisode(cat,1,'all')                
            }
            
        }


    },[latestEpisodes,fetchLatestEpisode,path])
}