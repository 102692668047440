import React from 'react';
import Container from 'react-bootstrap/Container';
import styles from './ContactAddress.module.scss';
import InnerHTML from 'dangerously-set-html-content'

const ContactAddress = ({data}) => {
    return (
                    <div className={styles.ContactAddress_class}>
                        <div className="container">
                        <div className="row">
                        {data?data.map((value)=>{ 
                            return(
                            <div className={`col-xs-4 ${styles.div_wrap}`}>
                                  <h4 className={styles.heading_wrap}>{value.location}</h4>
                                  <p className={styles.address_wrap}>
                                  <InnerHTML html={value.address} /> </p>
                                  <p className={styles.tel_para}>Tel : <a className={styles.tel} href={"tel:" + value.phone}>{value.phone}</a></p>
                                  <p className={styles.tel_para}>Email : <a className={styles.tel} href={"mailto:" + value.email}>{value.email}</a></p>
                            </div>   
                           
                            )
                            }):null}

                        </div> 
                        </div>
                    </div>
    )
}

export default ContactAddress;