import React,{useEffect} from "react";
import { connect } from "react-redux";
import {fetchSocialLink} from '../../actions/otherdetailsAction';
import styles from './SocialMediaIcons.module.scss';
import {FacebookShareButton,TwitterShareButton,WhatsappShareButton} from "react-share";

const SocialMediaWrap = ({socialLink,fetchSocialLink}) => {
    useEffect(()=>{
        if(socialLink.length==0){
            fetchSocialLink()
        }
    },[socialLink])
    return (
        <div className={styles.socialMediaicoWrap}>
            <ul className={styles.home_share} data-spy="affix" data-offset-top="600">
                {socialLink?socialLink.map((value)=>{
                    if(value.type === "facebook"){
                        return (
                            <li> 
                                <div role="button" tabindex="0" className="SocialMediaShareButton SocialMediaShareButton--facebook">
                                    <FacebookShareButton  url={window.location.href} ><a className="icon-social_fb"></a></FacebookShareButton>
                                </div>
                            </li>        
                            )
                    }
                    if(value.type === "twitter"){
                        return (
                            <li> 
                                <div role="button" tabindex="0" className="SocialMediaShareButton SocialMediaShareButton--twitter">
                                    <TwitterShareButton  url={window.location.href} ><a className="icon-social_twitter"></a></TwitterShareButton>
                                </div>
                            </li>        
                            )
                    }

                    if(value.type === "whatsapp"){
                        return (
                            <li> 
                                <div role="button" tabindex="0" className="SocialMediaShareButton SocialMediaShareButton--whatsapp">
                                    <WhatsappShareButton  url={window.location.href}><a className="icon-social_whatsapp"></a></WhatsappShareButton>
                                </div>
                            </li>        
                            )
                    }
                    
                }):null}
                
            </ul>
        </div>
    )
}

const mapStateToProps = (state) => {
    return ({socialLink:state.socialLink})
}

export default connect(mapStateToProps,{fetchSocialLink})(SocialMediaWrap);