import React,{useState,useEffect} from 'react'
import styles from './ConnectedTVTab.module.scss';
import { Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ReactPlayer from 'react-player';
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../../logic/Dimentions';
import { useHistory,useParams } from "react-router-dom";




const ConnectedTvTab = ({tabContent,tabType}) => {
    const [isDescTab, setDescTab] = useState(false);
    const [isVideo, setVideo] = useState(false);
    const [itemDescData, setItemDescData] = useState({});
    const [activeIndex,setActiveIndex] = useState(0);
    const { height, width } = useDimensions();
    let history = useHistory();
    let {platform} = useParams()

    useEffect(()=>{
        let index =0;
        let item = tabContent&&tabContent[0]
        if(platform){
            index=tabContent&&tabContent.findIndex(x => x.name ===platform)
            item=tabContent&&tabContent.find(x => x.name ===platform)
        }
        
        setItemDescData(item)
        setActiveIndex(index)
        setDescTab(true)
    },[platform])

    var blnVideo = 'block';
    function handleGetStarted(){
        setVideo(!isVideo)
    }
    const descSection = (item,index) =>{
        setDescTab(true)
        setItemDescData(item)
        setActiveIndex(index)
       history.push(`/watchon/${tabType}/${item.name}`);
    }

    const closeDescSection = () =>{
        setDescTab(false)
    }
    

      const TabDetails = () => {
          return(
            isDescTab?<div className={width>=992?styles.hidden_layer:styles.mobileTabDes} style={{display :'block'}}>
            <div className={styles.hidden_content}>
                {!isVideo ? (<div className={styles.hidden_image}>
                            <img src={itemDescData.contentImage && itemDescData.contentImage.url ? itemDescData.contentImage.url:itemDescData.contentImage}></img>
                        </div>): ( <ReactPlayer 
                            url={itemDescData.contentVideo}
                            className={styles.player}
                            controls={true}
                            style={{display : !isVideo ?'none':'block'}}
                        />)
                        
                        }
                        <div className={styles.text_wrap}>
                            <h3>{itemDescData.contentTitle}</h3>
                            <p>{itemDescData.description}</p>
                            {!isVideo ? (<a className="btn-outline-primary btn-lg" onClick={handleGetStarted}>Get Started</a>):
                            <a className={styles.web_btn_back} onClick={handleGetStarted} style={{display: blnVideo}}>BACK</a>}
                            {width>=992?<span className={styles.close_btn} onClick={closeDescSection}>×</span>:null}
                        </div>
                </div>
        </div>:null
          )
      }

    return(
        <>

        <Row className={styles.outer_layer}>
        {tabContent && tabContent.length > 0 ? (
                 tabContent.map((item,index)=>{
                     return(
                         <>
                        <Col key={item.contentImage.ID} md={3} className={`${styles.box_wrap} ${activeIndex==index&&styles.active} ${width>=992?styles.colorChange:styles.mobile}`} onClick={() => descSection(item,index)}>
                            <img src={item.titleImage}/>
                        </Col>
                        {(width>=992&&(index+1)%4==0||width<992)?
                        <>
                        {(width>=992&&parseInt(((index)/4))==parseInt(((activeIndex)/4))||(width<992&&index==activeIndex))?TabDetails():null}
                        </>:null}
                    </>
                     )
                 })
            ):null}
        </Row>
        
      


        </>
        
      
    )
}

export default ConnectedTvTab;
