import React,{useEffect} from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {connect} from 'react-redux';
import {fetchLiveSchedule} from '../../actions/LiveScheduleAction';
import _ from 'lodash';
import { FaPlayCircle,FaPauseCircle,FaPlay } from "react-icons/fa";
import styles from './UpcomingPrograms.module.scss';

const UpcomingPrograms = ({fetchLiveSchedule,liveSchedule,region,setRegion}) => {

    useEffect(()=>{
        if(liveSchedule.length==0){
            fetchLiveSchedule()
        }
    },[liveSchedule])

    let maxLength = Math.max(..._.toArray(liveSchedule)?.map(schedules=> schedules.schedules.length));

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 3
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 3
        }
      };

    return (
      <section className={styles.upcomingSec}>
<Container>
                   <Carousel responsive={responsive}>
       {_.toArray(liveSchedule).map((value)=>{
           return (
               <div className={styles.upcomingProgram} onClick={()=>setRegion(value.slug)}>
                    <div className={styles.caption}><span>LIVE</span> {value.caption}</div>
                    <div className={styles.name}> {region!==value.slug?<span className={styles.actionPlay}></span>:<span className={styles.actionPouse}></span>}  {value.name}</div>
                    {value?.schedules?.length>0?
                    value?.schedules.map((value2)=>{
                    return (
                    <div className={styles.programList}><div className={styles.program}>{value2?.name}</div>
                    <div className={styles.nowplay}> {value2?.start} - {value2?.end}</div></div>)
                    })
                    :null}
                    {_.times(maxLength-(value?.schedules.length), ()=><div className={styles.commingsoon}>Comming soon</div>)}
             </div>
           )
       })}

        </Carousel>
        </Container>
      </section>
        
        
    )

}

const mapStateToProps = (state) => {
    return {liveSchedule:state.liveSchedule}
}

export default connect(mapStateToProps,{fetchLiveSchedule})(UpcomingPrograms);