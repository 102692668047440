import React,{useState} from 'react';
import styles from './WatchonFormMail.module.scss';
import { useFormik } from 'formik';
import {salomapi3} from '../../apis'
const WatchonFromMail = () =>{
    const [formSuccessMessage, setFormSuccessMessage] = useState("");
    const validate = values => {
        const errors = {};
        if (!values.firstName) {
          errors.firstName = 'Required';
        } else if (values.firstName.length > 15) {
          errors.firstName = 'Must be 15 characters or less';
        }
      
        if (!values.lastName) {
          errors.lastName = 'Required';
        } else if (values.lastName.length > 20) {
          errors.lastName = 'Must be 20 characters or less';
        }
      
        if (!values.email) {
          errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }
        if (!values.confirmEmail) {
            errors.confirmEmail = ' ';
          } else if (values.email != values.confirmEmail) {
            errors.confirmEmail = 'Confirmed Email id is mismatching';
          }
    
        return errors;
      };

    const formik = useFormik({
        initialValues: {
          firstName:'',
          email: '',
          lastName:'',
          confirmEmail:''
        },
        validate,
        onSubmit: (values,{resetForm}) => {
           const postData = {
            cr_firstname : values.firstName,
            cr_lastname : values.lastName,
            cr_email    :values.email,
            cr_conf_email :values.confirmEmail,
           }
          
           salomapi3.post('/channelrequest',postData).then( response =>{
            setFormSuccessMessage(response.data.msg)
            resetForm({})
            setTimeout(()=>{
                setFormSuccessMessage("")
               },4000)


           })
        },
      });

    return(
        <div className={styles.ShalomWorldTvNetworkFormWrap}>
            <form action="" className="shalomNetReqForm" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="form-group col-sm-6">
                  <input type="text" name="firstName" placeholder="First Name" onChange={formik.handleChange} className={!formik.errors.firstName || !formik.submitCount ? '' : styles.formErrReact } value={formik.values.firstName} />
              </div>
              <div className="form-group col-sm-6">
                  <input type="text" onChange={formik.handleChange} name="lastName" placeholder="Last Name" className={!formik.errors.lastName || !formik.submitCount ? '' : styles.formErrReact } value={formik.values.lastName} />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-6">
                <input type="text" onChange={formik.handleChange} name="email" placeholder="Email Address" className={!formik.errors.email || !formik.submitCount ? '' : styles.formErrReact } value={formik.values.email} />
                {formik.errors.email && formik.submitCount ? (
                  <span className={styles.formtotNullfieldErr}>
                  Please Enter a valid Email Id
                  </span>
              ) : null}
              </div>
              <div className="form-group col-sm-6">
                <input type="text" name="confirmEmail" onChange={formik.handleChange} placeholder="Confirm Email Address" className={!formik.errors.confirmEmail || !formik.submitCount ? '' : styles.formErrReact } value={formik.values.confirmEmail} />
                {formik.errors.confirmEmail && formik.submitCount ? (
                    <span className={styles.formtotNullfieldErr}>
                    Confirmed Email id is mismatching
                    </span>
                ) : null}  
              </div>
            </div>
            
                
            <div className="form-group col-sm-12">
                <button type="submit" className={styles.web_btn}>Send Email</button>
            {Object.keys(formik.errors).length!=0 && formik.submitCount  ? (
              <div className="form-group col-sm-12">
                  <span className={styles.formtotNullfieldErr}>
                      Please fill all required fields.
                  </span>
                </div>
              ) : null}
              
              {formSuccessMessage ? (
              <div className="form-group col-sm-12">
                <span className={styles.resonseSuccessMsg}>
                 {formSuccessMessage}
                </span>
              </div>
            ) : null}
            </div>
            </form>
          </div>
    )
}
export default WatchonFromMail