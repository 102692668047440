import React from "react";
import styles from "./BlogList.module.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import InnerHTML from "dangerously-set-html-content";
import { Link } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import NewsThumb from '../Mobile/NewsThumb';
import {useDimensions} from '../../logic/Dimentions';


const BlogList = ({ blogList, loadMorePage }) => {
  const { height, width } = useDimensions();
  return (
    width>=992?<>
    <div className="bx-white-wrp">
      <Container>
        <div className={styles.blogbox_wrap}>
          <Row>
            {blogList?.map((blog) => {
              return (
                <div
                  key={blog.blogImageThumb}
                  className={`col-md-4 ${styles.blog_box}`}
                >
                  <Link to={"/news/" + blog.blogUrl}>
                    <img
                      src={blog.blogImage}
                      alt={blog.blogTitle}
                      className={styles.image_size}
                    />
                  </Link>
                  <span className={styles.content_wrap}>
                    <span className="head_cont">
                     
                       
                          {blog.category.length > 0 ? (
                            <p className={styles.blog_cat}>
                              {blog.category[0].category}
                            </p>
                          ) : null}
                       
                      
                          <p className={styles.blog_count}>
                           
                                <i className="icon-blog-view"></i>{" "}
                                <span>{blog.views}</span>
                             
                          </p>
                       
                      

                      {/* <span className={styles.newspgThumbVideoIco}></span> */}
                    </span>
                    <h2 className={styles.blog_cont}>
                      <Link to={"/news/" + blog.blogUrl}>{blog.blogTitle}</Link>
                    </h2>
                    <p className={styles.blog_cont_desc}>
                      {
                        <InnerHTML
                          html={
                            blog.blogDescription.substring(0, 270) + " . . ."
                          }
                        />
                      }
                    </p>
                    <span className={styles.foot_cont}>
                      <span className={styles.blog_auth}>
                        <p>
                        <img
                      src={blog.blogImage}
                      alt={blog.blogTitle}
                       />
                          By 
                          <label className="col-xs-4">
                            <a href="/blogauthor/renjith-leen">
                              {blog.authorName}
                            </a>
                          </label>
                         
                       
                        </p>
                        <p>   
                          <label className={`col-xs-8 ${styles.blog_date}`}>
                            {blog.modifieddate}
                          </label>
                          </p>
                      </span>
                    </span>
                  </span>
                </div>
              );
            })}
          </Row>
          <section>
            <a className={styles.load_btn} onClick={loadMorePage}>
              <button>Load more</button>
            </a>
          </section>
        </div>
      </Container>
      </div>
    </>:<>
    {blogList.map((blog) => {
        return(
            <NewsThumb value={blog}/>
        )
    })}
    </>
  );
};
export default BlogList;
