import styles from './SignUpTab.module.scss';
import React,{useState,useCallback} from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames'
import Row from 'react-bootstrap/Row';
import ReCAPTCHA from "react-google-recaptcha";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
import {salomapi3} from '../../apis';

const useStyles = makeStyles((theme) => ({
    inputRoot: {
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        // Default left padding is 6px
        paddingLeft: 26,
        color: "black",
      }
    }
  }));
const SignUpTab = ({countryList}) => {
    const [countryName, setCountryName] = useState("");
    const [isFormSuccess, setFormSuccess] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const divClass = classNames(styles.div_fields, 'col-sm-6')
    const inputClass = classNames(styles.input_fields)
    const classes = useStyles();
    const validate = values => {
        const errors = {};
        if (!values.firstName) {
          errors.firstName = 'Required';
        } else if (values.firstName.length > 15) {
          errors.firstName = 'Must be 15 characters or less';
        }
      
        if (!values.lastName) {
          errors.lastName = 'Required';
        } else if (values.lastName.length > 20) {
          errors.lastName = 'Must be 20 characters or less';
        }
      
        if (!values.email) {
          errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }
        if (countryName =="") {
          errors.country = 'Required';
        } 
        if (!values.domname) {
          errors.domname = 'Required';
        } 
        if (!values.password) {
          errors.password = 'Required';
        } 
        if (!values.cpassword) {
            errors.cpassword = 'Required';
          } 
        if (!values.phone) {
            errors.phone = 'Required';
        } 
        if (!values.location) {
            errors.location = 'Required';
        } 
        return errors;
      };
    const formik = useFormik({
        initialValues: {
          email: '',
        },
        validate,
        onSubmit: values => {
           const postData = {
            firstname : values.firstName,
            lastname : values.lastName,
            email    :values.email,
            domain :values.domname,
            location: values.location,
            country: countryName,
            password:values.password,
            phone:values.phone,
            repassword:values.cpassword
    
           }
           salomapi3.post('/mediaregister',postData).then( response =>{
               if(response.data.status === "success")
               setFormSuccess(true)
               else
               setErrorMsg(response.data.msg)
           })
          //  alert(JSON.stringify(values, null, 2));
        },
      });
      const CountryChange = useCallback((event, newValue) => {
       
        if(newValue)
        setCountryName(newValue.country_name);
        
     },[])
    return (
        <div className={styles.gallery_signup}>
            <h3>Get Started Now</h3>
           
                <form className={styles.form_align} onSubmit={formik.handleSubmit}>
                    <Row className={styles.div_align}>
                        <div className={divClass}>
                            <input className={inputClass}  type="text" onChange={formik.handleChange} value={formik.values.firstName} name="firstName" placeholder="First Name"></input>
                        </div>
                        <div className={divClass}>
                            <input className={inputClass} type="text" onChange={formik.handleChange} value={formik.values.lastName} name="lastName" placeholder="Last Name"></input>
                        </div>
                    </Row>
                    <Row className={styles.div_align}>
                        <div className={divClass}>
                            <input className={inputClass} type="text" onChange={formik.handleChange} value={formik.values.phone} name="phone" placeholder="Phone"></input>
                        </div>
                        <div className={divClass}>
                            <input className={inputClass} type="text" onChange={formik.handleChange} value={formik.values.email}  name="email" placeholder="Email Address"></input>
                        </div>
                    </Row>  
                    <Row className={styles.div_align}>
                        <div className={divClass}>
                        {/* <Autocomplete
                        id="combo-box-demo"
                        options={top100Films}
                        getOptionLabel={(option) => option.title}
                        
                        renderInput={(params) => <TextField {...params} className={styles.color} label="Combo box" variant="outlined" />}
                        /> */}
                        <Autocomplete
     
                            id="combo-box-demo"
                            classes={classes}
                            options={countryList}
                            getOptionLabel={(option) => option.country_name}
                            renderOption={(option) => (
                            <div style={{ color: "white" }}>{option.country_name}</div>
                                    )}
                                style={{ width: 204 }}
                                onChange={CountryChange}
                                renderInput={(params) => <TextField className={inputClass} {...params} placeholder="Select Country" name="country" variant="outlined" />}
                            />
                            {/* <input className={inputClass} type="search" autocomplete="on" name="SelectCountry" placeholder="Select Country"></input> */}
                        </div>
                        <div className={divClass}>
                            <input className={inputClass} type="text" onChange={formik.handleChange} value={formik.values.location} name="location" placeholder="Enter Location"></input>
                        </div>
                    </Row>
                    <Row className={styles.div_align}>
                        <div className={divClass}>
                            <input className={inputClass} type="password" onChange={formik.handleChange} value={formik.values.password} name="password" placeholder="Password"></input>
                        </div>
                        <div className={divClass}>
                            <input className={inputClass} type="password" onChange={formik.handleChange} value={formik.values.cpassword} name="cpassword" placeholder="Confirm Password"></input>
                        </div>
                    </Row>  
                    <Row className={styles.div_align}>
                            <input className={styles.textClass} onChange={formik.handleChange} value={formik.values.domname} name="domname" placeholder="DomainName"></input>
                    </Row> 
                    <Row className={styles.div_align}>
                        <ReCAPTCHA className={styles.captchaClass}
                        sitekey="Your client site key"
                        />
                    </Row>  
                    <button className={styles.signup_btn} type="submit">SUBMIT</button>
                    {Object.keys(formik.errors).length!=0 && formik.submitCount ?
                                    <div className="form-group col-sm-12">
                                       <span className={styles.formtotNullfieldErr}>Please fill all required fields.</span>
                                    </div>:null
                    }

                    {isFormSuccess?
                                    <div className="form-group col-sm-12">
                                       <span className={styles.resonseSuccessMsg}>Your account registered successfully!!!</span>
                                    </div>:null
                                }
                    {errorMsg ?
                                    <div className="form-group col-sm-12">
                                       <span className={styles.resonseFailedMsg}>{errorMsg}</span>
                                    </div>:null
                                }
                </form>
                
          
        </div>
        
    )

}

export default SignUpTab;
