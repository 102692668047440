import React from 'react';
import CommonLayout from '../components/Layouts/CommonLayout';
import OurShows from '../components/OurShows';

const homePage = () => {
    return (
        <CommonLayout>
        <OurShows/>
        </CommonLayout>
        )
}

export default homePage;