import { useState,useEffect } from 'react';
export const useSwprayerLiveVideo = props => { 
    const {fetchToken,getMassSteamUri} = props
    const [watchUrl,setWatchUrl] = useState('')

    useEffect(()=>{
        fetchToken().then((value)=>{
            getMassSteamUri(value).then((value2)=>{
                setWatchUrl(`${value2.url}?token=${value.secure_token}&timestamp=${value.timestamp}&clientId=${value.client_id}&region=masslive`)
            })
        })
    },[])
    return {
        watchUrl
    }
}