import React,{useEffect, useState} from "react";
import ReactPlayer from 'react-player';
import { FaPlay,FaPause,FaVolumeUp,FaVolumeMute,FaExpand,FaVolumeDown } from "react-icons/fa";
import Slider from '@material-ui/core/Slider';
import styles from './VideoPlayer.module.scss';


const VideoPlayer = ({url,fullscreen,setFullscreen,playError,setPlayError,live}) => {

    const [play,setPlay] = useState(false)
    const [volume,setVolume] = useState(100)

    const handleChange = (event, newValue) => {
        setVolume(newValue);
      };

    const playErrorHandler = () => {
      setPlayError(true)
    }


  return (
    <div className={`${styles.videoPlayer} player1`}>
      {/* <ReactPlayer
        url={url}
        className={styles.player}
        volume={volume}
        width={"100%"}
        playing={play}
        height={"100%"}
        controls={false}
        onError={playErrorHandler}
        pip={true}
        // onClick={()=>{setPlay(!play);console.log(play)}}
      /> */}
      {url==''?<img src="https://ark.shalomworld.org/wp-content/uploads/2020/08/1920X1080_Live3.jpg" width="100%" onClick={()=>{setPlayError(true)}}/>:
      <ReactPlayer
      url={url}
      className={styles.player}
      volume={volume}
      width={"100%"}
      playing={play}
      height={"100%"}
      controls={false}
      onError={playErrorHandler}
      pip={true}
      // onClick={()=>{setPlay(!play);console.log(play)}}
    />
      }
      <div className={styles.control}>
        <button
          onClick={() => {
            if(url==''){
                setPlayError(true)
            }
              setPlay(!play);
            
          }}
        >
          {!play ? <FaPlay /> : <FaPause />}
        </button>
        <button
          onClick={() => {
            setVolume(volume == 1 ? 0 : 1);
          }}
        >
          {volume == 1 ? (
            <FaVolumeUp />
          ) : volume > 0 ? (
            <FaVolumeDown />
          ) : (
            <FaVolumeMute />
          )}
        </button>
        <div className={styles.slider}>
          <Slider
            value={volume}
            min={0}
            step={0.1}
            max={1}
            scale={(x) => x ** 10}
            valueLabelDisplay="off"
            onChange={handleChange}
          />
        </div>
        <button
          className={styles.rightIcon}
          onClick={() => {
            setFullscreen(!fullscreen);
          }}
        >
          {fullscreen ? <FaExpand /> : <FaExpand />}
        </button>
      </div>
    </div>
  );
};

export default VideoPlayer;
