import React from 'react';
import CommonLayout from '../components/Layouts/CommonLayout';
import { useHome } from '../logic/Home/useHome';
import Feedback from '../components/Feedback';
import {connect} from 'react-redux';
import {fetchOtherdetails} from '../actions/otherdetailsAction';

const FeedbackPage = ({fetchOtherdetails,otherdetails}) => {
     useHome({fetchOtherdetails,otherdetails});
    return (
        <CommonLayout>
        <Feedback/>
        </CommonLayout>
    )
}
const mapStateToProps = (state) => {
    return ({otherdetails:state.otherdetails})
  }
export default connect(mapStateToProps,{fetchOtherdetails})(FeedbackPage);