import styles from './ContactUs.module.scss';
import React from 'react';
import {connect} from 'react-redux';
import {fetchContactus} from '../../actions/contactusAction';
import Container from 'react-bootstrap/Container';
import {UseContact} from "../../logic/ContactUs/useContact"
import classNames from 'classnames'
import Row from 'react-bootstrap/Row';
import ReCAPTCHA from "react-google-recaptcha";
import ContactAddress from '../ContactAddress';

 
function onChange(value) {
  }

const contactPage = ({fetchContactus,contactUs}) => {
    UseContact({fetchContactus,contactUs})
 
    const inputClass = classNames(styles.input_fields)
    const divClass = classNames(styles.div_fields, 'col-sm-6')

    const top100Films = [
        { title: 'The Shawshank Redemption', year: 1994 },
        { title: 'The Godfather', year: 1972 },
        { title: 'The Godfather: Part II', year: 1974 },
        { title: 'The Dark Knight', year: 2008 },
        { title: '12 Angry Men', year: 1957 },
        { title: "Schindler's List", year: 1993 },
        { title: 'Pulp Fiction', year: 1994 },
        { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
        { title: 'The Good, the Bad and the Ugly', year: 1966 },
        { title: 'Fight Club', year: 1999 }
    ]
    return (
        <>
         <div  className="contact-wrp" style={{background:`url("https://ark.shalomworld.org/wp-content/uploads/2018/12/contact-banner-1919x985.jpg")`}}>
        <div className={styles.headingAlign} style={{ 
            backgroundImage: contactUs.background
          }}>
            <Container>
            <h1>Contact Us</h1>

                <form className={styles.form_align}>
                    <Row className="col-lg-12">
                        <div className={divClass}>
                            <input className={inputClass} type="text" name="Firstname" placeholder="First Name"></input>
                        </div>
                        <div className={divClass}>
                            <input className={inputClass} type="text" name="Lastname" placeholder="Last Name"></input>
                        </div>
                    </Row>
                    <Row className="col-lg-12">
                        <div className={divClass}>
                            <input className={inputClass} type="text" name="Phone" placeholder="Phone"></input>
                        </div>
                        <div className={divClass}>
                            <input className={inputClass} type="text" name="Email" placeholder="Email"></input>
                        </div>
                    </Row>  
                    <Row className="col-lg-12">
                        <div className={divClass}>
                        {/* <Autocomplete
                        id="combo-box-demo"
                        options={top100Films}
                        getOptionLabel={(option) => option.title}
                        
                        renderInput={(params) => <TextField {...params} className={styles.color} label="Combo box" variant="outlined" />}
                        /> */}
                            <input className={inputClass} type="search" autocomplete="on" name="SelectCountry" placeholder="Select Country"></input>
                        </div>
                        <div className={divClass}>
                            <input className={inputClass} type="text" name="SelectState" placeholder="Select State"></input>
                        </div>
                    </Row>
                    <Row className={`col-lg-12 ${styles.div_fields}`}>
                            <textarea className={styles.textClass} name="Phone" placeholder="Describe your message, program feedback or prayer request here"></textarea>
                    </Row> 
                    <Row className={`col-lg-12 ${styles.div_fields}`}>
                        <ReCAPTCHA className={styles.captchaClass}
                        sitekey="Your client site key"
                        onChange={onChange}
                        />
                    </Row>  
                    <Row className={`col-lg-12 ${styles.div_fields}`}>
                        <a className={styles.web_btn}>SUBMIT</a>
                    </Row>  
                </form>
            </Container>
        </div>
         <h2 className={styles.is_title}>Get in touch with us</h2>
         <ContactAddress data={contactUs.address?contactUs.address:null}/>
         </div>
         </>
        
    )

}

const mapStateToProps = (state) => {
    return ({contactUs:state.contactUs})
}

export default connect(mapStateToProps,{fetchContactus})(contactPage);