import {FETCH_LIVE_SCHEDULE,FETCH_FULL_SCHEDULE} from './types';
import salomapi from '../apis';

export const fetchLiveSchedule =  () => async dispatch => {
    const response = await salomapi.get('live/schedules2');
    dispatch({type:FETCH_LIVE_SCHEDULE,payload:response.data.schedules})
    
}

export const fetchLiveSchedule2 = (region) => async dispatch => {
    const response = await salomapi.get('full/schedules',{params:{region}});
    dispatch({type:FETCH_FULL_SCHEDULE,payload:response.data.schedules})
}