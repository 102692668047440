import React from 'react';
import WatchOnVideo from '../components/WatchOnVideo';
import CommonLayout from '../components/Layouts/CommonLayout';
import {fetchOtherdetails} from '../actions/otherdetailsAction';
import { useHome } from '../logic/Home/useHome';
import {connect} from 'react-redux';

const WatchOn = ({fetchOtherdetails,otherdetails}) => {
    useHome({fetchOtherdetails,otherdetails});
    return (
        <CommonLayout>
        <WatchOnVideo/>
        </CommonLayout>
    )
}
const mapStateToProps = (state) => {
    return ({otherdetails:state.otherdetails})
  }
export default connect(mapStateToProps,{fetchOtherdetails})(WatchOn);