import React from 'react';
import CommonLayout from '../components/Layouts/CommonLayout';
import Episodes from '../components/Episodes';

const episodePage = () => {
    return (
        <CommonLayout>
        <Episodes/>
        </CommonLayout>
    )
}

export default episodePage;