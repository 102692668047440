import styles from './MediaGallery.module.scss';
import React,{useState,useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import {fetchMediaGallery} from "../../actions/mediagalleryAction"
import {fetchCountryList} from '../../actions/swprayerAction'
import {connect} from 'react-redux';
import AboutTab from '../AboutTab';
import SignUpTab from '../SignUpTab';
import SignInTab from '../SignInTab';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const MediaGallery = ({fetchMediaGallery,fetchCountryList,mediaGallery,countryList}) => {
    const [value, setValue] = useState(0);
    const [tabActive,SetTabActive] = useState(1);
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
      useEffect(()=>{
        // if(fellowship.length ==0)
        fetchMediaGallery()
        if(countryList.length ==0)
        fetchCountryList()
    },[])
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box p={3}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
      }
    return (
        <>
        <div className={styles.mediaGallery} style={{background:`url(${mediaGallery.banner})`}}>
            <Container>
                <h1 className={styles.is_title}>Media Gallery</h1>
                <div className={styles.gallerytab_wrap}>

                <ul className={styles.tabs}>
                  <li className={tabActive==1?styles.active:null} onClick={()=>{SetTabActive(1)}}>About</li>
                  <li className={tabActive==2?styles.active:null} onClick={()=>{SetTabActive(2)}}>Sign up</li>
                  <li className={tabActive==3?styles.active:null} onClick={()=>{SetTabActive(3)}}>Sign in</li>
                </ul>

                {tabActive==1? <AboutTab signupWindow={() =>handleChange(null,1)} galleryData={mediaGallery}/>:null}
                {tabActive==2? <SignUpTab countryList={countryList}/>:null}
                {tabActive==3? <SignInTab/>:null}

                {/* <Tabs value={value} className={styles.tab_style} onChange={handleChange}   aria-label="simple tabs example">
                   <Tab label="About"  />
                   <Tab label="Sign Up"  />
                   <Tab label="Sign In"  />
                </Tabs> */}
                {/* <TabPanel value={value} index={0}>
                <AboutTab signupWindow={() =>handleChange(null,1)} galleryData={mediaGallery}/>
                </TabPanel>
                <TabPanel value={value} index={1}>
                <SignUpTab countryList={countryList}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                <SignInTab/>
                </TabPanel> */}

                </div>
            </Container>
        </div>
        </>
        
    )

}
const mapPropsToState = (state) => {
     return {mediaGallery:state.mediaGallery.mediaGallery,countryList:state.swprayer.countryList,}
}
export default connect(mapPropsToState,{fetchMediaGallery,fetchCountryList})(MediaGallery);