import React,{useEffect} from 'react'
import {fetchWatchOnDetails} from "../../actions/watchonAction"
import ReactPlayer from 'react-player/lazy'
import styles from './WatchOnVideo.module.scss';
import Container from 'react-bootstrap/Container';
import WatchOnSection from '../WatchOnSection';
import {connect} from 'react-redux';
import {useDimensions} from '../../logic/Dimentions';


const WatchOnVideo = ({fetchWatchOnDetails,watchonDetails}) => {
    const { height, width } = useDimensions();
    useEffect(()=>{
        fetchWatchOnDetails()
    },[])
    return(
        <div>
            {width>=992?<Container className={styles.video_align}>
              <ReactPlayer 
                url={watchonDetails.bannerVideo}
                width="100%"
                height="100%"
                controls = {true}
                playing
                playIcon={
                <Container className={styles.hover_content}>
                <span className={`icon-play_video ${styles.icon_play}`}></span>
                <h1 className={styles.video_heading}>{watchonDetails.banner}</h1>
                </Container>
                }
                light={watchonDetails.bannerImage}/>
            </Container>:null}
            <WatchOnSection watchonDetails={watchonDetails} />
        </div>
    )
}
const mapPropsToState = (state) => {
return {watchonDetails:state.watchon.watchonDetails}
}
export default connect(mapPropsToState,{fetchWatchOnDetails})(WatchOnVideo);
