import React,{useState} from 'react';
import styles from '../WatchonFormMail/WatchonFormMail.module.scss';
import { useFormik } from 'formik';
import {salomapi3} from '../../apis'
const WatchonFormRequest = ({zipCode,channel}) =>{
    const [formSuccessMessage, setFormSuccessMessage] = useState("");
    const validate = values => {
        const errors = {};
        if (!values.firstName) {
          errors.firstName = 'Required';
        } else if (values.firstName.length > 15) {
          errors.firstName = 'Must be 15 characters or less';
        }
      
        if (!values.lastName) {
          errors.lastName = 'Required';
        } else if (values.lastName.length > 20) {
          errors.lastName = 'Must be 20 characters or less';
        }
      
        if (!values.email) {
          errors.email = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }
        if (!values.confirmEmail) {
            errors.confirmEmail = ' ';
          } else if (values.email != values.confirmEmail) {
            errors.confirmEmail = 'Confirmed Email id is mismatching';
          }
          if (!values.Address1) {
            errors.Address1 = 'Required';
          } 
          if (!values.Address2) {
            errors.Address2 = 'Required';
          } 
          if (!values.city) {
            errors.city = 'Required';
          } 
          if (!values.state) {
            errors.state = 'Required';
          } 
          if (!values.phone) {
            errors.phone = 'Required';
          } 
    
        return errors;
      };

    const formik = useFormik({
        initialValues: {
          firstName:'',
          email: '',
          lastName:'',
          confirmEmail:'',
          Address1:'',
          Address2:'',
          city:'',
          state:'',
          phone:''
        },
        validate,
        onSubmit: (values,{resetForm}) => {
           const postData = {
            firstname : values.firstName,
            lastname : values.lastName,
            email    :values.email,
            email2 :values.confirmEmail,
            channelId :channel.id,
            channelName:channel.channel,
            city:values.city,
            phone:values.phone,
            state:values.state,
            zipcode: zipCode,
            address1: values.Address1,
            address2:values.Address2
           }
          
           salomapi3.post('/channel/request/',postData).then( response =>{
            setFormSuccessMessage(response.data.msg)
            resetForm({})
            setTimeout(()=>{
                setFormSuccessMessage("")
               },4000)


           })
        },
      });

    return(
        <div className={styles.ShalomWorldTvNetworkFormWrap}>
            <form action="" className="shalomNetReqForm" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="form-group col-sm-6">
                  <input type="text" name="firstName" placeholder="First Name" onChange={formik.handleChange} className={!formik.errors.firstName || !formik.submitCount ? '' : styles.formErrReact } value={formik.values.firstName} />
              </div>
              <div className="form-group col-sm-6">
                  <input type="text" onChange={formik.handleChange} name="lastName" placeholder="Last Name" className={!formik.errors.lastName || !formik.submitCount ? '' : styles.formErrReact } value={formik.values.lastName} />
               </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-6">
                <input type="text" onChange={formik.handleChange} name="email" placeholder="Email Address" className={!formik.errors.email || !formik.submitCount ? '' : styles.formErrReact } value={formik.values.email} />
                {formik.errors.email && formik.submitCount ? (
              <div className="form-group col-sm-12">
                <span className={styles.formtotNullfieldErr}>
                Please Enter a valid Email Id
                </span>
              </div>
            ) : null}
              </div>
              <div className="form-group col-sm-6">
                <input type="text" name="confirmEmail" onChange={formik.handleChange} placeholder="Confirm Email Address" className={!formik.errors.confirmEmail || !formik.submitCount ? '' : styles.formErrReact } value={formik.values.confirmEmail} />
                {formik.errors.confirmEmail && formik.submitCount ? (
              <div className="form-group col-sm-12">
                <span className={styles.formtotNullfieldErr}>
                Confirmed Email id is mismatching
                </span>
              </div>
            ) : null}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-sm-6">
                  <textarea onChange={formik.handleChange} 
                  placeholder="Address 1 *" 
                  value={formik.values.Address1} 
                  className={!formik.errors.Address1 || !formik.submitCount ? styles.textarea : styles.textarea +" "+styles.formErrReact} 
                  name="Address1">
                </textarea>
              </div>
              <div className="form-group col-sm-6">
                  <textarea onChange={formik.handleChange} 
                  placeholder="Address 2 *" 
                  value={formik.values.Address2} 
                  className={!formik.errors.Address2 || !formik.submitCount ? '' : styles.formErrReact} 
                  name="Address2">
                  </textarea>
              </div>
            </div>
            <div className="row">
                <div className="form-group col-sm-6">
                    <input type="text"  onChange={formik.handleChange} name="city" placeholder="City" className={!formik.errors.city || !formik.submitCount ? '' : styles.formErrReact } value={formik.values.city} />
                </div>
                <div className="form-group col-sm-6">
                    <input type="text" name="state" onChange={formik.handleChange} placeholder="State" className={!formik.errors.state || !formik.submitCount ? '' : styles.formErrReact } value={formik.values.state} />
                </div>
            </div>          
            <div className="row">
                <div className="form-group col-sm-6">
                  <input type="text" name="phone" onChange={formik.handleChange} placeholder="Phone" className={!formik.errors.phone || !formik.submitCount ? '' : styles.formErrReact } value={formik.values.phone} />
                  </div>
                  <div className="form-group col-sm-6">
                  <input type="text" name="pincode" placeholder="Postcode" value={zipCode}  />
                  </div>
            </div>      
            <div className="form-group col-sm-12">
                 <button type="submit" className={styles.web_btn}>Send Email</button>
                  {Object.keys(formik.errors).length!=0 && formik.submitCount  ? (
                     <div className="form-group col-sm-12">
                        <span className={styles.formtotNullfieldErr}>
                            Please fill all required fields.
                         </span>
                        </div>
                    ) : null}
            
              
              {formSuccessMessage ? (
              <div className="form-group col-sm-12">
                <span className={styles.resonseSuccessMsg}>
                 {formSuccessMessage}
                </span>
              </div>
            ) : null}
            </div></form>
            </div>
    )
}
export default WatchonFormRequest