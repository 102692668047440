import React,{lazy} from 'react';
import PrivacyStatements from '../components/PrivacyStatements';
const Menu = lazy(()=>import('../components/Menu'));
const Subscription = lazy(()=>import('../components/Subscription'));
const Footer = lazy(()=>import('../components/Footer'));

const Statements = () => {
    return (
        <>
        <Menu/>
        <PrivacyStatements/>
        <Subscription/>
        <Footer/>
        </>
    )
}
export default Statements;  