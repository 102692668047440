import React from 'react';
import CommonLayout from '../components/Layouts/CommonLayout';
import { useHome } from '../logic/Home/useHome';
import Careers from '../components/Careers';
import {connect} from 'react-redux';
import {fetchOtherdetails} from '../actions/otherdetailsAction';

const Career = ({fetchOtherdetails,otherdetails}) => {
     useHome({fetchOtherdetails,otherdetails});
    return (
        <CommonLayout>
        <Careers/>
        </CommonLayout>
        
    )
}
const mapStateToProps = (state) => {
    return ({otherdetails:state.otherdetails})
  }
export default connect(mapStateToProps,{fetchOtherdetails})(Career);