import React,{useEffect,useState} from 'react';
import {fetchProgramDetails} from '../../actions/programeAction';
import {connect} from 'react-redux';
import {useParams} from "react-router-dom";
import styles from './ShowDetails.module.scss';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import ProgrameThumb from '../ProgrameThumb';
import Episodethumb from '../EpisodeThumb';
import {isMobile} from 'react-device-detect';
import EpisodeThumbMobile from '../Mobile/EpisodeThumb';
import EpisodeListShimmer from '../Shimmers/EpisodeListShimmer/EpisodeListShimmer';
import EpisodeAboutMobile from '../Mobile/EpisodeAbout';
import {useDimensions} from '../../logic/Dimentions';
import Modal from 'react-bootstrap/Modal';
import VideoPlayer from '../VideoPlayer';
import _ from 'lodash';



const ShowDetails = ({fetchProgramDetails}) => {

    let  {showId}  = useParams();
    const [programDetails,setProgramDetails] = useState([])
    const [stickyMenu1,setStickyMenu1] = useState(false)
    const [nonStickyMenu1,setNonStickyMenu1] = useState(false)
    const [showDescription,setShowDescription] = useState(false)
    const [buttomTab,setButtomTab] = useState(4)
    const { height, width } = useDimensions();
    const [watchPromo, setWatchPromo] = useState(false);
    const [fullscreen,setFullscreen] = useState(false);
    const [playError,setPlayError] = useState(false);

    useEffect(()=>{
        window.addEventListener('scroll', handleScroll);
    },[])

    useEffect(()=>{
        fetchProgramDetails(showId).then((value)=>{
            setProgramDetails(value)
            console.log(programDetails.promoVideo[0]?.video)
            if(value.childPrograms?.length==0){
                setButtomTab(4)
            }
        }).catch(err=>console.log(err))
    },[])
    const handleScroll = (e) => {
        
        if(window.pageYOffset>950){
         setStickyMenu1(true)
       }else{
         setStickyMenu1(false)
       }
       if(window.pageYOffset<940)
       setNonStickyMenu1(true)
       else
       setNonStickyMenu1(false)
 }

 const support = () => {
    window.location.href =`https://payments.shalommedia.org/tvdonation/${showId}`
 }
    return (
        <section className={styles.showDetails}>
            <div className={styles.BottomDark}></div>
            <figure className={styles.showImg}>
                <img src={programDetails.bannerImg} className={styles.headerImg} style={{position:width<992?'relative':'fixed'}}/>
            </figure>
            
            <div className={styles.showsInner}>
            {width>=992?
        
               <Container>
                <div className={styles.bannerTitleSection}>
                    <h2>{programDetails&&programDetails.title}</h2>
                    <p>{programDetails.description&&<>{showDescription?programDetails?.description:programDetails?.description?.substring(0,120)+'...'} <span onClick={()=>setShowDescription(!showDescription)}>{showDescription?`Read less`:`Read more`}</span></>}</p>
                    <div className={styles.schedule}>
                        <p>Schedule:</p>
                        <p>{programDetails.schedule}</p>
                    </div>
                    <button className={styles.web_btn} onClick={()=>{setWatchPromo(true)}}>Watch Promo Video</button>
                </div></Container>:null}
                <nav className={stickyMenu1?styles.stickMenu:null}>
                <Container >
            {/* <ul className={`${nonStickyMenu1?styles.submenu:null}`}> */}
            <ul className={`${nonStickyMenu1?styles.submenu:styles.submenu}`}>
            {programDetails.childPrograms?.length>0?<li className={buttomTab==1?styles.active:''} onClick={()=>setButtomTab(1)}>Related Show</li>:null}
            <li className={buttomTab==4?styles.active:''} onClick={()=>setButtomTab(4)}>Latest Episode</li>
            <li className={buttomTab==2?styles.active:''} onClick={()=>setButtomTab(2)}>Related Episode</li>
            <li className={buttomTab==3?styles.active:''} onClick={()=>setButtomTab(3)}>About</li>
            </ul>
            </Container >
                </nav>
                
            <Container className={styles.showsThumbRow}>
            <Row>
            {buttomTab==1&&programDetails.childPrograms?programDetails?.childPrograms?.map((value)=>{  
            return(<ProgrameThumb value={value}/>)
            }):null}
            {buttomTab==4 ? programDetails.latestEpisodes?programDetails?.latestEpisodes?.episodes?.map((value)=>{  
            // return(width<992?<EpisodeThumbMobile value={value}/>:<Episodethumb value={value}/>)
            return(<Episodethumb value={value}/>)
        }):(_.times(6, ()=><EpisodeListShimmer/>)):null}
        {buttomTab==2 ? programDetails.relatedEpisodes?programDetails?.relatedEpisodes?.episodes.map((value)=>{  
            // return(width<992?<EpisodeThumbMobile value={value}/>:<Episodethumb value={value}/>)
            return(<Episodethumb value={value}/>)
        }):(_.times(6, ()=><EpisodeListShimmer/>)):null}
        {buttomTab==3&&programDetails?  
            <div className={styles.aboutSection}>
                <h1>About {programDetails.title}</h1>
                <p>{programDetails.description}</p>
                <button className={styles.web_btn} onClick={support}>Support</button>
            </div>
        :null}
            </Row>
            </Container>
           </div>
            <Modal className="watchPromoPop"
                show={watchPromo}
                onHide={() => setWatchPromo(false)}
            >
                <Modal.Body>
                {programDetails?.promoVideo?.length>0?<VideoPlayer url={programDetails?.promoVideo[0]?.video} fullscreen={fullscreen} setFullscreen={setFullscreen} playError={playError} setPlayError={setPlayError}/>:null}
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default connect(null,{fetchProgramDetails})(ShowDetails);