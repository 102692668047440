import React,{lazy,Suspense} from 'react';
import Row from 'react-bootstrap/Row';
// import Episodethumb from '../EpisodeThumb';
import EpisodeListShimmer from '../Shimmers/EpisodeListShimmer/EpisodeListShimmer';
import _ from 'lodash';
const Episodethumb = lazy(()=>import('../EpisodeThumb'));
const EpisodeList = ({data}) => {
    return (
        <Row>
        {data && data?.length > 0 ?data.map((value,index)=>{  
            return(<Suspense ><Episodethumb value={value} key={index}/></Suspense>)
        }): _.times(6, ()=><EpisodeListShimmer/>)}
        {/* {_.times(6, ()=><EpisodeListShimmer/>)} */}
        </Row>
    )
}

export default EpisodeList;