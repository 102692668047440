import React,{useState} from 'react';
import Container from 'react-bootstrap/Container';
import styles from './MassPetition.module.scss';
import { Formik, Field, ErrorMessage,useFormik, FormikConsumer } from 'formik';
import ThanksforSubmission from "../ThanksforSubmission"
import {Form,Row,Col, NavItem} from 'react-bootstrap'
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReCAPTCHA from "react-google-recaptcha";
import { makeStyles } from "@material-ui/core/styles";
import ModernDatepicker from 'react-modern-datepicker';
import salomapi from '../../apis';


const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      paddingLeft: 26,
      color: "white",
    }
  }
}));

const MassPetition = ({countryList,CountryChange,CountryInputChange,selectDate,startDate,stateList,weekTimeList,countryName,countryInputName}) => {
  const [state, setState] = useState("");
  const [isFormSuccess, setFormSuccess] = useState(false);
  const validate = values => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = 'Required';
    } else if (values.firstName.length > 15) {
      errors.firstName = 'Must be 15 characters or less';
    }
  
    if (!values.lastName) {
      errors.lastName = 'Required';
    } else if (values.lastName.length > 20) {
      errors.lastName = 'Must be 20 characters or less';
    }
  
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (countryInputName =="") {
      errors.country = 'Required';
    } 
    if (!values.massPetitionDesc) {
      errors.massPetitionDesc = 'Required';
    } 
    if (!values.address) {
      errors.address = 'Required';
    } 
    if (!values.city) {
      errors.city = 'Required';
    } 
    if (!values.postCode) {
      errors.postCode = 'Required';
    } 
    if(!state) {
      errors.state = 'Required';
    }
    if(!startDate)
    errors.date = 'Required'
    if(!time)
    errors.time = 'Required'
    return errors;
  };
  
const StateChange = (event, newInputValue) => {
  setState(newInputValue)
 // setValue(newValue);
}
const [time, setTime] = useState('');
const [charCount, setCharCount] = useState(0);
  const selectTime = (event) => {
    setTime(event.target.value);
  };
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      firstName:'',
      email: '',
      lastName:'',
      address:'',
      city:'',
      postCode:'',
      massPetitionDesc:''
    },
    validate,
    onSubmit: (values,{resetForm}) => {
       const postData = {
        firstname : values.firstName,
        lastname : values.lastName,
        address  :values.address,
        email    :values.email,
        city :values.city,
        state: state,
        zipcode:values.postCode,
        country:countryInputName,
        intention:values.massPetitionDesc,
        date:startDate,
        time: (time.time.split(" "))[4] + " " + (time.time.split(" "))[5],
        personname:"",
        church_details:time.church

       }
       salomapi.post('/swprayer/massrequest',postData).then( response =>{
        setFormSuccess(true)
        resetForm({})
        setState("")
        CountryInputChange("","")
        selectDate("")
        setTime("");

       })
      //  alert(JSON.stringify(values, null, 2));
    },
  });
  const massPetitionChange = (val) =>{

    // let len = formik.values.massPetitionDesc.length
    if(val.target.value.length<=250)
    formik.handleChange(val)
  
    // setCharCount(val.target.value.length)
  }
  const alertClose = () =>{
    setFormSuccess(false)
    window.scrollTo(0, 1150);
}
  
    return (
        <div>
        <Container >
            {/* <h4>Mass description goes here</h4> */}
            <p className={styles.mass_petition_para}>
              <i>The Lord hears his people when they call to him for help. He rescues them from all their troubles. (Psalm 34:17)</i>
            </p>
            <p className={styles.mass_petition_para}>Please complete the Mass Petition form below and your petitions will be prayed for in our Daily Mass.</p>
            {/* <Formik

initialValues={{ email: '',}}
validate={values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address';
  }

  return errors;

}}

onSubmit={(values, { setSubmitting }) => {
  setTimeout(() => {

    alert(JSON.stringify(values, null, 2));

    setSubmitting(false);

  }, 400);

}}

> */}

{/* {({errors,touched,handleSubmit,isSubmitting }) => ( */}



  <Form className={styles.form} onSubmit={formik.handleSubmit}>
  <Row>
    <Col>
      {/* <Form.Control placeholder="First name *" className={styles.input} /> */}
      <input type="text" onChange={formik.handleChange} value={formik.values.firstName} name="firstName" className={!formik.errors.firstName || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact } placeholder="First name *"></input>
    </Col>
    <Col>
      <input placeholder="Last name *" onChange={formik.handleChange} value={formik.values.lastName} name="lastName" className={!formik.errors.lastName || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact }></input>

    </Col>
  </Row>
  <Row>
    <Col>
      <input placeholder="Email *" onChange={formik.handleChange} id="email" value={formik.values.email}  name="email" className={!formik.errors.email || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact}></input>
    </Col>
    <Col>
    <Autocomplete
     
      id="combo-box-demo"
      classes={classes}
      options={countryList}
      getOptionLabel={(option) => option.country_name}
      renderOption={(option) => (
        <div style={{ color: "white" }}>{option.country_name}</div>
      )}
      style={{ width: 404 }}
      inputValue={countryInputName}
      onInputChange={CountryInputChange}
      // value={countryName}
      onChange={CountryChange}
      renderInput={(params) => <TextField className={styles.input} {...params} placeholder="Select Country" name="country" className={!formik.errors.country || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact} variant="outlined" />}
    />
      {/* <input placeholder="Select Country *" className={styles.input}></input> */}
    </Col>
  </Row>
  <Row>
    <Col>
      <input placeholder="Address" onChange={formik.handleChange} value={formik.values.address} name="address" className={!formik.errors.address || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact}></input>
    </Col>
    <Col>
      <input placeholder="City" onChange={formik.handleChange} value={formik.values.city} name="city" className={!formik.errors.city || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact}></input>
    </Col>
  </Row>
  <Row>
    <Col>
    <Autocomplete
     
      id="combo-box-demo"
      classes={classes}
      options={stateList.length> 0 ? stateList :countryList}
      getOptionLabel={(option) => stateList.length> 0 ? option.name:option.country_name}
      renderOption={(option) => (
        <div style={{ color: "white" }}>{stateList.length> 0 ? option.name:option.country_name}</div>
      )}
      style={{ width: 404 }}
      inputValue={state}
      onInputChange={StateChange}
      renderInput={(params) => <TextField  className={!formik.errors.state || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact} {...params} placeholder="Select State/ Province" variant="outlined" />}
    />
      {/* <input placeholder="Select state / Province" className={styles.input}></input> */}
    </Col>
    <Col>
      <input placeholder="Postal Code" onChange={formik.handleChange} value={formik.values.postCode} name="postCode" className={!formik.errors.postCode || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact}></input>
    </Col>
  </Row>
  <Row>
    <Col   style={{ zIndex: 1 }}>
    <ModernDatepicker
                className={!formik.errors.date || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact}
                date={startDate}
                format={'DD MMM YYYY'}
                showBorder
                onChange={selectDate}
                placeholder="Select Date *"
                primaryColor={'#00000'}
                secondaryColor={'#00000'}
                primaryTextColor={'#FFFFFF'}
                secondaryTextColor={'#FFFFFF'}
              
            />
      {/* <input placeholder="Select Date *" className={styles.input}></input> */}
    </Col>
    <Col>
    <Select
          className={!formik.errors.time || !formik.submitCount ? styles.input : styles.input +" "+styles.formErrReact}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={time}
          placeholder="Select Time *"
          onChange={selectTime}
        >
         { weekTimeList && weekTimeList.length > 0 ? (
           weekTimeList.map((time) => {
            return(
              <MenuItem style={{color:"white"}} value={time}>{(time.time.split(" "))[4] + " " + (time.time.split(" "))[5] + " - " + time.church}</MenuItem>
            )
            
           })):null } 
         
        </Select>
      {/* <input placeholder="Select Time *" className={styles.input}></input> */}
    </Col>
  </Row>
  <Row>
    <Col>
      <textarea onChange={massPetitionChange} 
      placeholder="Mass Petition *" 
      value={formik.values.massPetitionDesc} 
      className={!formik.errors.massPetitionDesc || !formik.submitCount ? styles.textarea : styles.textarea +" "+styles.formErrReact} 
      name="massPetitionDesc">

      </textarea>
      <p className={styles.textarea_para}> characters {formik.values.massPetitionDesc ? formik.values.massPetitionDesc.length : 0} / 250</p>
      {formik.errors.massPetitionDesc ? <div>{formik.errors.massPetitionDesc}</div> : null}
    </Col>
  </Row>
  {Object.keys(formik.errors).length!=0 && formik.submitCount  ? (
                     <div className="form-group col-sm-12">
                        <span className={styles.formtotNullfieldErr}>
                            Please fill all required fields.
                         </span>
                        </div>
                    ) : null}
  {formik.errors.email && formik.submitCount ? (
              <div className="form-group col-sm-12">
                <span className={styles.formtotNullfieldErr}>
                Please Enter a valid Email Id
                </span>
              </div>
            ) : null}
              {formik.values.massPetitionDesc && formik.values.massPetitionDesc.length >= 250 ? ( <div className="form-group col-sm-12">
                <span className={styles.formtotNullfieldErr}>
                  Sorry your max charachter limit exceeded
                </span>
              </div>) :null} 
  <Row className={`col-lg-12 ${styles.div_fields}`}>
      <ReCAPTCHA className={styles.captchaClass}
      sitekey="Your client site key"
      />
  </Row>  
  <Row>
    <Col>
      <button className={styles.mass_petition_btn} disabled={formik.isSubmitting} type="submit">SUBMIT</button>
    </Col>
  </Row>
   
</Form>

{/* } */}

{/* </Formik> */}
    {isFormSuccess ?   <ThanksforSubmission alertClose={alertClose} /> : null}
    
        </Container>
        </div>
    )
}

export default MassPetition;