import React from 'react';
import Container from 'react-bootstrap/Container';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import styles from './SwprayerTab.module.scss'
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../../logic/Dimentions';


const SwprayerTab = ({value, setValue}) => {
    const handleChange = (event, newValue) => {
        setValue(newValue);
      };
  const { height, width } = useDimensions();


    return (
        
        width>=992?
            <div className={styles.Container}>
                 <div className="container">
                <Tabs aria-label="simple tabs example" onChange={handleChange} value={value} className={styles.tabContainer}>
                    <Tab label="Daily Mass"  className={styles.tab} />
                    <Tab label="Mass Petition" className={styles.tab} />
                    <Tab label="Prayer Request" className={styles.tab} />
                    <Tab label="Testimony" className={styles.tab}/>
                    <Tab label="About" className={styles.tab}/>
                </Tabs>
                </div>
           </div>
           :( <div className="container"><ul className={styles.mobTab}>
                <li onClick={()=>setValue(0)}>Daily Mass</li>
                <li onClick={()=>setValue(1)}>Mass Petition</li>
                <li onClick={()=>setValue(2)}>Prayer Request</li>
                <li onClick={()=>setValue(3)}>Testimony</li>
                <li onClick={()=>setValue(4)}>About</li>
               </ul></div>)
            
        
    )
}

export default SwprayerTab;