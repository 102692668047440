import React,{lazy} from 'react';
import './CommonStyle.scss';
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../../logic/Dimentions';
const Menu = lazy(()=>import('../Menu'));
const Donate = lazy(()=>import('../Donate'));
const RelatedSites = lazy(()=>import('../RelatedSites'));
const OtherSection = lazy(()=>import('../OtherSection'));
const MediaPartners = lazy(() => import ('../MediaPartners'));
const FindShalom = lazy(()=>import('../FindShalom'));
const Subscription = lazy(()=>import('../Subscription'));
const Footer = lazy(()=>import('../Footer'));
const MobileMenu =  lazy(()=>import('../MobileMenu'));
const BottomTab = lazy(()=>import('../BottomTab'));



const MainLayout = ({children}) => {
    const { height, width } = useDimensions();
    return (
        <>
        {width>=992?<Menu/>:<MobileMenu/>}
        <div>{children}</div>
        
        {width>=992?
        <>
        <Donate/>
        <RelatedSites/>
        <OtherSection/>
        <MediaPartners/>
        <FindShalom/>
        <Subscription/>
        <Footer/></>:<BottomTab/>}
        </>
    )
}

export default MainLayout;