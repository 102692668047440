

import React,{lazy,useEffect} from 'react';
import BlogList from '../components/BlogList';
import {fetchNewsDescription} from '../actions/newsAction';
import {connect} from 'react-redux';
import {useParams} from "react-router-dom";
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../logic/Dimentions';
import NewsDetails from '../components/NewsDetails';
const Menu = lazy(()=>import('../components/Menu'));
const Subscription = lazy(()=>import('../components/Subscription'));
const Footer = lazy(()=>import('../components/Footer'));
const MobileMenu =  lazy(()=>import('../components/MobileMenu'));
const BottomTab = lazy(()=>import('../components/BottomTab'));

const NewsDetailPage = ({fetchNewsDescription,newsDescription}) => {
    let  {newsId}  = useParams();
    const { height, width } = useDimensions();

    useEffect(()=>{
        var url = newsId;
        fetchNewsDescription(url);
        
    },[])
    return (
        <>
        {width>=992?<Menu/>:<MobileMenu/>}
        <NewsDetails newsDetails={newsDescription} />
        {newsDescription ?  <BlogList blogList={newsDescription.relatedBlogs} />:null    } 
        {width>=992?
        <>
        <Subscription/>
        <Footer/>
        </>:<BottomTab/>}
        </>
    )
}

const mapStateToProps = (state) =>{
     return ({newsDescription:state.news.newsDescription})
    }
export default connect(mapStateToProps,{fetchNewsDescription})(NewsDetailPage);