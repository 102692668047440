import styles from './AboutTab.module.scss';
import React from 'react';
import Container from 'react-bootstrap/Container';

const AboutTab = ({signupWindow,galleryData}) => {
    return (
        <Container className={styles.aboutTab}>
        <div className={styles.gallery_intro}>
            <h3>{galleryData.heading}</h3>
            <p className={styles.gallery_para}>{galleryData.shortDescription}</p>
            <div className={styles.img_wrap}>
                <img src={galleryData.contentImage}></img>
            </div>
        </div>
        <div className={styles.desc} dangerouslySetInnerHTML={{__html: galleryData.description}}>

        </div>
         
        <a className={styles.signup_btn} onClick={signupWindow}>Sign Up Now</a>
        </Container>
        
    )

}

export default AboutTab;