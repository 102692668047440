import {FETCH_COMMON_SEARCH} from '../actions/types.js';

const INTIAL_STATE = {
    searchResult:[]
};

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_COMMON_SEARCH:
            return {
                ...state,
                searchResult:action.payload
            }
        default:
            return state;
    }
}