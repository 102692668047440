import React, { Component } from 'react'
// import logo from '../../img/logo.svg'
class MailGivingFormPrint_Page extends React.Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

   



    render() {
        return (
            <div style={{"backgroundColor":"#fff","height":"100%","width":"100%","position":"fixed", "padding":"30px"}}>
                <table width="100%">
                    <tr>
                        <th>
                            {/* <img src={logo} style={{"display":"block","margin":"0 auto","width":"150px","marginBottom":"20px"}} alt="" /> */}
                        </th>
                    </tr>
                </table>
                <table width="100%" style={{"marginBottom":"5px","fontWeight":"normal","font-size":"14px"}}>
                    <thead>
                        <tr>
                            <th cellpadding="15" style={{"padding":"20px"}}>
                                " Donate and spread faith, hope and love through your actions "
                            </th>
                        </tr>
                    </thead>
                </table>
                <table width="100%" style={{"marginBottom":"15px","fontWeight":"bold","backgroundColor":"#f5f5f5"}}>
                    <thead>
                        <tr>
                            <th cellpadding="30" style={{"padding":"20px"}}>
                                Shalom World TV {this.props.type} Pledge Details
                            </th>
                        </tr>
                    </thead>
                </table>
              
                <table cellpadding="20px" width="100%" style={{"border":"solid 1px #f1f1f1","padding":"15px"}}>
                    <tbody>
                        <tr>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>{this.props.type} Amount</td>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>:{" "+this.props.monthlyValue}{" "}{this.props.currCode}/month</td>
                        </tr>
                        <tr>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>Name</td>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>:{" "+this.props.printData.title+". "}{this.props.printData.firstName}{" "}{this.props.printData.lastName}</td>
                        </tr>
                        <tr>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>Email</td>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>:{" "+this.props.printData.email}</td>
                        </tr>
                        <tr>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>Primary Phone</td>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>:{" "+this.props.printData.phone}</td>
                        </tr>
                        <tr>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>Mobile Phone</td>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>:{" "+this.props.printData.phone2?this.props.printData.phone2:"No Data"}</td>
                        </tr>
                        <tr>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>Street Address</td>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>:{" "+this.props.printData.address}</td>
                        </tr>
                        <tr>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>apt, suit, ebldg</td>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>:{" "+this.props.printData.apartment?this.props.printData.apartment:"No Data"}</td>
                        </tr>
                        <tr>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>Country</td>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>:{" "+this.props.country}</td>
                        </tr>
                        <tr>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>State</td>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>:{" "+this.props.state}</td>
                        </tr>
                        <tr>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>City</td>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>{": "+this.props.printData.city}</td>
                        </tr>
                        <tr>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>Zipcode</td>
                            <td style={{"padding":"12px 20px", "fontSize":"14px"}}>{": "+this.props.printData.zipcode}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default MailGivingFormPrint_Page;