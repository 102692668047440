import React,{useEffect} from 'react';
import Container from "react-bootstrap/Container";
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {fetchTestimonies} from '../../actions/testimoniesAction';
import {connect} from 'react-redux';
import styles from './testimonies.module.scss';

const Testimonies = ({fetchTestimonies,testimonies}) => {

    useEffect(()=>{
        if(testimonies==0){
            fetchTestimonies()
        }
    },[testimonies])

    return (
        <Container className={styles.testimonies}>
        <h1>Testimonies</h1>
        <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={75}
        totalSlides={testimonies.length}
        visibleSlides={3}
        >
            <Slider>
                {testimonies.map((value,index)=>{
                    return (
                        <Slide index={index} className={styles.slider}>
                            <img src={value.photo} className={styles.image}/>
                            <p className={styles.testi_para}>{value.content}</p>
                            <span className={styles.testi_name}>{value.firstname} {value.lastname}</span>
                        </Slide>
                    )
                })}
            </Slider>
        </CarouselProvider>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {testimonies:state.testimonies}
}

export default connect(mapStateToProps,{fetchTestimonies})(Testimonies);