import React,{useEffect, useState} from 'react'
import VideoPlayer from "../VideoPlayer";
import {connect} from 'react-redux';
import {fetchEpisodeVideo} from '../../actions/episodeVideoAction';
import {fetchLatestVideo,fetchRelatedVideo,removeLatestRelatedVideo} from '../../actions/episodesAction';
import {useParams,Link} from "react-router-dom";
import BrowserPersistence from '../../util/simplePersistance';

import styles from './EpisodeVideo.module.scss';
import Container from 'react-bootstrap/Container'
import EpiosdeRelatedVideo from './EpisodeRelatedVideos';
import SecMenu from '../SecMenu';
import {isMobile} from 'react-device-detect';
import {useDimensions} from '../../logic/Dimentions';
import MobileMenu from '../MobileMenu'




const storage = new BrowserPersistence();
const EpisodeVideo = ({fetchEpisodeVideo,episodeVideo}) => {
     
      let  {episodeId}  = useParams();
      const [fullscreen, setFullscreen] = useState(false);
      const { height, width } = useDimensions();

      // useEffect(()=>{
      //   if(episodeVideo==0){
      //     fetchEpisodeVideo(episodeId,'us')
      //   }
      // },[episodeVideo])

      useEffect(()=>{
        fetchEpisodeVideo(episodeId,storage.getItem('CountryCode'))
        
        // fetchEpisodeVideo(showId).then((value)=>{
        //     setProgramDetails(value)
            
        // }).catch(err=>console.log(err))
    },[])


    return(
        <div className={styles.EpisodeVideo}>
          {width>=992?<SecMenu/>:<MobileMenu/>}
            <div className={styles.video_align}>
            <VideoPlayer
            url={episodeVideo.videos?episodeVideo.videos[1].url:null}
            fullscreen={fullscreen}
            setFullscreen={setFullscreen}
            />
            </div>
            {width>=992?
            <div className={styles.body}>
            <Container>
                <><div className={styles.is_title}>
                <h1>{episodeVideo.title}</h1>
                </div>
                <p className={styles.episode}>
                    <span>{episodeVideo.episode}-{episodeVideo.program}</span>
                </p>
                <p className={styles.episode}><span>{episodeVideo.description}</span></p>
                <Link className={styles.web_btn} to={'/feedback'}>LEAVE A FEEDBACK</Link></>
            </Container>
            </div>
            :<div className="container"><h5>{episodeVideo.title}</h5></div>}
            <EpiosdeRelatedVideo episodeVideo={episodeVideo}/>           
        </div>

    )


}

const mapStateToProps = (state) =>{
return ({episodeVideo:state.episodeVideo.episodeVideos,
  maxLatestPage:state.episodeVideo.maxLatestPages,
  maxRelatedPage:state.episodeVideo.maxRelatedPages,
  moreLatestVideos:state.latestEpisodes.latestVideos,
  moreRelatedVideos:state.latestEpisodes.relatedVideos,
  currentLatestPage:state.latestEpisodes.currentLatestPage,
  currentRelatedPage:state.latestEpisodes.currentRelatedPage})
}

export default connect(mapStateToProps,{fetchEpisodeVideo,fetchLatestVideo,fetchRelatedVideo,removeLatestRelatedVideo})(EpisodeVideo);
