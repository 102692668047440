import React from 'react'
import styles from './PrivacyStatements.module.scss';
import Container from 'react-bootstrap/Container'

const PrivacyStatements = () => {
    return(
        <div className={styles.stmnts_div}>
            <div className={styles.sw_cmsWrap}>
            <div className="container">
                <h1 className={styles.stmnt_heading}>PRIVACY STATEMENT</h1>
                <div className={styles.stmnt_para}>
                    <p>
                        <strong>SHALOM WORLD PRIVACY POLICY</strong>
                    </p>
                    <p>HOW SHALOMWORLD.ORG USES YOUR PERSONAL INFORMATION</p>
                    <div>
                        <p>SHALOMWORLD.ORG takes your privacy seriously. Our policy was created to affirm and practice our commitment to maintain the privacy of our constituents and to inform you of the practices we follow in protecting your Personally Identifiable Information. This policy covers how shalomworld.org treats personal information that shalomworld.org collects and receives, including information related to your use of shalomworld.org services and products.</p>
                        <p>Our policy was created to affirm and practice our commitment to maintaining the privacy of our constituents and to inform you of the practices we follow in protecting your Personally Identifiable Information.</p>
                        <p>&nbsp;</p>
                        <p>Shalomworld.org collects personal information when you register with Shalomworld.org when you use shalomworld.org products or services, when you visit shalomworld.org pages or download from the site. Shalomworld.org may combine information about you that we have with information we obtain from business partners (e.g. Sophia books) or other companies.</p>
                        <p>WE COLLECT INFORMATION</p>
                    </div>
                    <div>
                        <p>“Personally identifiable information” (PII), is</p>
                    </div>
                    <div>
                        <p>information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context.</p>
                    </div>
                    <div>
                        <div>
                        <p>We collect your when you:</p>
                        <p>• • • • • • •</p>
                        </div>
                        <div>
                        <p>Personally Identifiable Information at shalomworld.org. Some of these might include</p>
                        <p> Visit our web site    

                            Log onto our website
                            
                            Sign up to receive information about a particular ministry;

                            Receive prayer or other ministry services;

                            Request or purchase a product at the bookstore, via the web, or through the telephone; Make a donation to Shalom Media or Shalom Media Ministries; and

                            For a variety of other reasons not listed here.
                        </p>
                        </div>
                    </div>
                    <div>
                        <p>If you give us your Personally Identifiable Information for any reason, we assure that your information to be strictly confidential and private.</p>
                        <p>When we collect, use or disclose your Personally Identifiable Information we will obtain your consent to do so unless such permission is obvious or implied.Shalomworld.org automatically receives and records information from your computer and browser, including your IP address, Shalomworld.org cookie information, software and hardware attributes, and the page you request.</p>
                        <p>Shalomworld.org uses information for the following general purposes:</p>
                        <ul>
                            <li>
                            to customize the content you see,
                            </li>
                            <li>fulfill your requests for products and services,</li>
                            <li>improve our services, contact you, conduct research, and</li>
                            <li>provide anonymous reporting for internal and external clients.</li>
                        </ul>
                        <p>
                            <strong>WE COLLECT INFORMATION FOR</strong>&nbsp;
                            :We collect information to provide better services to all of our users.We use your Personally Identifiable Information to provide you with the services or products for which you requested us with
                        </p>
                    </div>
                </div>

                <div className={styles.stmnt_para}>
                    <p>your information, and/or for those purposes considered reasonable under the circumstances. We do not sell your information to third parties and we may share your information with our subsidiaries or with other shalom ministries from whom we obtain services, or when it is necessary to comply with the law or to protect the rights, property or safety of Shalom Media.</p>
                    <p><strong>ACCURACY OF THE INFORMATION</strong></p>
                    <p>Shalom Media endeavors to keep your Personally Identifiable Information as accurate as possible so that it can continue to provide you with the products and services you’ve requested. For this, we will need your assistance in maintaining the accuracy of your information. You may request a correction to your Personally Identifiable Information by emailing info@Shalomworld.org</p>
                    <p>
                        <strong>DURATION OF THE INFORMATION</strong>
                    </p>
                    <p>We retain your Personally Identifiable Information for as long as necessary to fulfill the purpose for which you gave it or for any legal or business purpose. When your information is no longer required by Shalom Media for any reason, we have procedures in place to destroy it, delete it, or convert it to anonymous form.</p>
                    <p>
                        <strong>INFORMATION SHARING AND DISCLOSURE</strong>
                    </p>
                    <p>Shalom Media is committed to protecting your Personally Identifiable Information from unauthorized access or disclosure or use. For this, we do our best to maintain maximum security both in our technology and our physical records. We also limit access to your information only to persons management determines need to know for the purpose of providing you with the requested products and services. Shalomworld.org does not rent, sell, or share personal information about you with other people or non-affiliated companies except to provide products or services you’ve requested, when we have your permission, or under the following circumstances:</p>
                    <p>We respond to subpoenas, court orders, or legal process (such as law enforcement requests), or</p>
                    <p>to establish or exercise our legal rights or defend against legal claims. We believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Shalomworld.org terms of use, or as otherwise required by law.</p>
                    <p>We will share personal information with companies, organizations or individuals outside of Shalom Media when we have your consent to do so. We require opt-in consent for the sharing of any sensitive personal information. If your Shalom Media account is managed for you by a domain administrator then your domain administrator and resellers who provide user support to your organization will have access to your Shalom Media Account information (including your email and other data). Your domain administrator may be able to:</p>
                    <ul>
                        <li>view statistics regarding your account, like statistics regarding applications you install.</li>
                        <li>change your account password.</li>
                        <li>suspend or terminate your account access.</li>
                        <li>access or retain information stored as part of your account.</li>
                        <li>receive your account information in order to satisfy applicable law, regulation, legal process or enforceable governmental request.</li>
                        <li>restrict your ability to delete or edit information or privacy settings.</li>
                    </ul>
                </div>
                </div>
            </div>
          
        </div>
    )
}

export default PrivacyStatements;

