import React,{useEffect} from 'react'
import {connect} from 'react-redux';
import ReactPlayer from 'react-player/lazy'
import {fetchPeaceFellowship} from "../../actions/fellowshipAction"
import styles from './PeaceFellowship.module.scss';
import Container from 'react-bootstrap/Container'
import WaysToGive from '../WaysToGive';
import Row from 'react-bootstrap/Row';

const PeaceFellowship = ({fetchPeaceFellowship,fellowship}) => {
    useEffect(()=>{
        // if(fellowship.length ==0)
        fetchPeaceFellowship()
    },[])
    return(
        <div>
            <div className={styles.video_align}>
                <div className="vdoWrap wide">
                {fellowship.bannerVideo && fellowship.bannerVideo.length > 0 ? (
                    <ReactPlayer url={fellowship.bannerVideo[0].url} 
                    width='100%'
                    height='100%'
                    controls = {true}/>
              ):null}
                </div>
              
            </div>
            <section className={styles.spf_intro}>
                <Container>
                    <div className={styles.logo_wrap}>
                        <img src={fellowship.fellowshipLogo}></img>
                    </div>
                    <p className={styles.para_spf}>
                    {fellowship.fellowshipDescp}
                    </p>
                    <div className={styles.para_spf} dangerouslySetInnerHTML={{__html: fellowship.fellowshipShortDescp}}>

                    </div>
                    {/* <p className={styles.para_spf}>
                    Shalom Media has been fully funded by supporters like you and me, who embrace the vision of ‘Shalom’ to fulfill its mission. The Good News of Jesus is too good to keep to ourselves: let’s share His Peace and Joy to the ends of the Earth!
                    </p>
                    <p className={styles.para_spf}>
                    “No gift is too big… no gift is too small.”
                    </p>
                    <p className={styles.last_para}>
                    Will you make a commitment to support global evangelization with your monthly donation? 
                    </p> */}
                    <a className={styles.web_btn}>JOIN NOW</a>
                    <div className={styles.donate_method}>
                        <span>OR</span>
                        <Row className={styles.row_style}>
                            <div className="col-sm-6">
                                <div className={styles.method_box}>
                                    <h5>{fellowship.fellowShipOr ? fellowship.fellowShipOr.us.heading:null}</h5>
                                    <p dangerouslySetInnerHTML={{__html: fellowship.fellowShipOr ? fellowship.fellowShipOr.us.address:null}}>
                                    </p>
                                    <a href="/peacefellowship/mail">{fellowship.fellowShipOr ? fellowship.fellowShipOr.us.btnTitle:null}</a>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className={styles.method_box}>
                                    <h5>{fellowship.fellowShipPhone ? fellowship.fellowShipPhone.us.heading:null}</h5>
                                    <h5 className={styles.phone_no}>{fellowship.fellowShipPhone ? fellowship.fellowShipPhone.us.btnUrl:null}</h5>
                                    <p>{fellowship.fellowShipPhone ? fellowship.fellowShipPhone.us.address:null}
                                    </p>
                                    <a href="">{fellowship.fellowShipPhone ? fellowship.fellowShipPhone.us.btnTitle:null}</a>
                                </div>
                            </div>
                        </Row>
                    </div>
                </Container>
            </section>
         
                <WaysToGive moreWays={fellowship.moreWaysGive} />
           
        </div>
    )
}
const mapPropsToState = (state) => {
     return {fellowship:state.fellowshipData.fellowship}
}
export default connect(mapPropsToState,{fetchPeaceFellowship})(PeaceFellowship);

