import {FETCH_VIDEO,REMOVE_VIDEO} from '../actions/types';

const INTIAL_STATE = {
    episodeVideos:[],
    maxLatestPages:null,
    maxRelatedPages:null
};

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_VIDEO:
            return{
                ...state,
                episodeVideos:action.payload,
                maxLatestPages:action.maxLatestPage,
                maxRelatedPages:action.maxRelatedPage
            } 
            case REMOVE_VIDEO:
                return{
                    ...state,
                    episodeVideos:action.payload,
                    maxLatestPages:action.maxLatestPage,
                    maxRelatedPages:action.maxRelatedPage
                } 

        default:
            return state;
    }
}