import React,{useState} from 'react';
import Container from 'react-bootstrap/Container'
import styles from './OurShows.module.scss';
import ProgramList from '../ProgramList';
import {fetchPrograms,fetchCategoryPrograms} from '../../actions/programeAction';
import CategoryList from '../CategoryList';
import {isMobile} from 'react-device-detect';
import {connect} from 'react-redux';
import {useDimensions} from '../../logic/Dimentions';
import {useProgramList} from "../../logic/ProgramList/useProgramList";
import { useParams,useHistory } from "react-router-dom";
const OurShows = ({fetchPrograms,categories,fetchCategoryPrograms,programes}) => {

   const [categoryData,SetCatgeory] = useState("")
  const { height, width } = useDimensions();
  const {location} = useHistory();
  let path = location.pathname;
  let {category} = useParams()
  useProgramList({fetchPrograms,programes,category,categories,path,fetchCategoryPrograms})
    return (
        <div  className={styles.headingAlign}>
            <Container>
            <div className={styles.bgImageWrap_new}><img src={programes.bgImage} /></div>
                {width>=992? <h1 className={styles.ttlHome}>Our Shows</h1>:null}
                <CategoryList SetCatgeory={SetCatgeory}/>
        <div>
               <ProgramList category={categoryData} categoryProgrames={programes}/>
        </div>
            </Container>
        </div>
    )
}
const mapStateToProps = (state) =>{
    return ({programes:state.programes,categories:state.categories})
    }
    
    export default connect(mapStateToProps,{fetchPrograms,fetchCategoryPrograms})(OurShows);