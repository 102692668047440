import React,{useState} from 'react';
import styles from './ChannelFinderTab.module.scss';
import {useDimensions} from '../../logic/Dimentions';
import { useFormik } from 'formik';
import {postZipcode} from '../../actions/channelFinderAction';
import BrowserPersistence from '../../util/simplePersistance';
import {connect} from 'react-redux';
import ChannelFinderAreaList from './ChannelFinderAreaList';
import WatchonFormMail from "../WatchonFormMail"
import WatchonFormRequest from '../WatchonFormRequest';
import { Link,useLocation } from 'react-router-dom';

const storage = new BrowserPersistence();
const ChannelFinderTab = ({postZipcode}) => {
  const location = useLocation();
  const region = storage.getItem('CountryCode')
  const { height, width } = useDimensions();
  const [output,setOutput] = useState();
  const validate = values => {
    const errors = {}
    if (!values.zipCode) {
      errors.zipCode = 'Required';
    } 
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      zipCode: location?.state?.zipCode?location?.state?.zipCode:'',
    },
    validate,
    onSubmit: values => {
      postZipcode(values.zipCode,region).then((result)=>{
          setOutput(result)
      })
    
    },
  });

    return (
            <div className={width>=992?styles.chanfind_tab:styles.chanfindMob}>
                <div className={styles.chanfind_contain}>
                    <h2>What channel is Shalom World TV?</h2>
                    <p>Enter your ZIP Code to see channel results for your area.</p>
                    <form onSubmit={formik.handleSubmit}>
                        <input placeholder="Enter your ZIP Code" type="text" name="zipCode" value={formik.values.zipCode} required onChange={formik.handleChange}/>
                        <a>
                            →
                            <input type="submit"/>
                        </a>
                        {width>=992&&<span>Shalom World TV in : {formik.values.zipCode}</span>}
                    </form>
                    
                    <ChannelFinderAreaList output={output} zipCode={formik.values.zipCode}/>
                    {output&&
                    <div className={styles.bottomPra}>
                        {/* <WatchonFormMail /> */}
                        {/* <WatchonFormRequest zipCode={formik.values.zipCode} /> */}
                        <p>Good news! Shalom World is now available on connected TV devices like Apple TV, Amazon Fire TV, Roku, Android TV and xboxone. <Link className={styles.readMore} to={'/watchon/connectedtv'}>Read More</Link> </p>
                        <p>Also, download Shalom World app for free on your iOS and android devices. <Link className={styles.readMore} to={'/watchon/apps'}>Read More</Link> </p>
                    </div>
                    }
                </div>

            </div>        
    )
}

export default connect(null,{postZipcode})(ChannelFinderTab);















