import {FETCH_PEACE_FELLOWSHIP,FETCH_MAIL_FORM_DETAILS} from '../actions/types.js';

const INTIAL_STATE = {
    fellowship:[],
    mailFormDetails:{}
};
export default (state = INTIAL_STATE,action) => {
    switch(action.type){ 
        case FETCH_PEACE_FELLOWSHIP: 
        return {
            ...state,
            fellowship:action.payload
        }
        case FETCH_MAIL_FORM_DETAILS: 
        return {
            ...state,
            mailFormDetails:action.payload
        }
        default:
            return state;
    }
}