import logos from '../Assets/logo.svg';
import logo_media from '../Assets/logo_media.svg';
import logo_tidings from '../Assets/logo_tidings.svg';
import logo_store from '../Assets/logo_store.svg';

const Assets = {
    logos,
    logo_media,
    logo_tidings,
    logo_store
}

export default Assets;