import {FETCH_ABOUTUS,FETCH_MORE_ENDORSEMENTS} from '../actions/types.js';

const INTIAL_STATE = {
    aboutUs:[],
    moreEndoresments:[],
    maxPage:1
};

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_ABOUTUS:
            return {
                ...state,
                aboutUs : action.payload
            } 
            case FETCH_MORE_ENDORSEMENTS:
                
                    return{
                        ...state,
                        moreEndoresments: state.moreEndoresments.concat(action.payload),
                        maxPage: action.maxPage,
                        page:action.page
                    }

        default:
            return state;
    }
}