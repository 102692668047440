import {FETCH_OTHER_DETAILS,FETCH_SOCIALLINK} from './types';
import salomapi from '../apis';

export const fetchOtherdetails = () => async dispach => {
    const response = await salomapi.get('/otherdetails');
    dispach({type:FETCH_OTHER_DETAILS,payload:response.data})
}

export const fetchSocialLink = () => async dispach => {
    const response = await salomapi.get('/sociallinks')
    dispach({type:FETCH_SOCIALLINK,payload:response.data.socialLinks})
}