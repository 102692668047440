import React,{useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import Episodethumb from '../EpisodeThumb';
import {fetchDailymass} from '../../actions/dailymassAction';
import {connect} from 'react-redux';
import EpisodeListShimmer from '../Shimmers/EpisodeListShimmer/EpisodeListShimmer';
import _ from 'lodash';


const DailymassList = ({fetchDailymass,dailymass}) => {

    useEffect(()=>{
        if(dailymass.length==0){
            fetchDailymass()
        }
    },[dailymass])

    return (
        <Container>
        <Row>
            {dailymass && dailymass.latestEpisodes?.episodes.length > 0 ?dailymass?.latestEpisodes?.episodes.map((value)=>{
                return(<Episodethumb value={value}/>)
            }): _.times(6, ()=><EpisodeListShimmer/>)}
        </Row>
        </Container>
    )
}

const mapStateToProps = (state) => {
    return {dailymass:state.dailymass}
}

export default connect(mapStateToProps,{fetchDailymass})(DailymassList);