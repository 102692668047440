import styles from './Careers.module.scss';
import React,{useCallback} from 'react';
import {connect} from 'react-redux';
import {fetchCareers,fetchMoreJobs} from '../../actions/careersAction';
import Container from 'react-bootstrap/Container';
import {UseCareers} from "../../logic/Careers/useCareers"
import CareerSection from '../CareerSections';
import CurrentOpenings from '../CurrentOpenings';

const CareersPage = ({fetchCareers,careers,moreJobs,fetchMoreJobs}) => {
    UseCareers({fetchCareers,careers})
    let loadPage = 1
    const loadMoreJobs = useCallback(() =>{
         loadPage++
         if(careers.jobs)
         fetchMoreJobs(loadPage,careers.jobs.career)
    },[careers])
    return (
        <>
        <div className={styles.headingAlign}>
            <div class="career-banner">
                <img src={careers.banner}/>
            </div>
        
            <Container>
                <h1>Careers</h1>
                <h3 className={styles.career_intro}>{careers.heading}</h3>
                <p className={styles.career_des}>{careers.description}</p>
            </Container>
        </div>
        <CareerSection data={careers.sections}/>
        <div className={styles.currentOpenings}>
        <div className="container"> 
        <h1>Current Openings</h1>
        </div>
       {moreJobs.length > 0 ? (<CurrentOpenings data={moreJobs} loadMoreJobs={loadMoreJobs}/>)
       :(<CurrentOpenings data={careers.jobs?careers.jobs.career:null} loadMoreJobs={loadMoreJobs}/>)} 
        </div>
        </>
        
    )

}

const mapStateToProps = (state) => {
    return ({careers:state.careers.careers,moreJobs:state.careers.moreJobs})
}

export default connect(mapStateToProps,{fetchCareers,fetchMoreJobs})(CareersPage);