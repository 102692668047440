import  {salomapi2, salomapi3} from '../apis';
import {FETCH_WATCH_LIVE_IMAGES} from './types';

export const fetchToken = () => async dispatch => {
    const response = await salomapi3.get('/live/token');
    return response.data;
}

export const getSteamUri = (data,region) => async dispatch => {
    const response = await salomapi3.get('/live/stream',{params:{token:data.secure_token,timestamp:data.timestamp,clientId:data.client_id,region}});
    return response.data;
}

export const getMassSteamUri = (data) => async dispatch => {
    const response = await salomapi3.get('/live/stream',{params:{token:data.secure_token,timestamp:data.timestamp,clientId:data.client_id,region:'masslive'}});
    return response.data;
}

export const fetchLiveImages = () => async dispatch => {
    const response = await salomapi2.get('livethumb');
    dispatch({type:FETCH_WATCH_LIVE_IMAGES,payload:response.data.data})
}