import React from 'react';
import Container from 'react-bootstrap/Container';
import styles from './WaysToGive.module.scss';

const WaysToGive = ({moreWays}) => {
   
    return (
                    <section className={styles.more_ways}>
                         <Container>
                        <h3 className={styles.is_title}>More Ways To Give</h3>
                        <div className="row">
                            {moreWays && moreWays.length>0 ? ( 
                                  moreWays.map((item,key)=>{
                                      return(
                                        <div key={item.readMoreUrl} className={`col-md-4 col-xs-6${styles.image_wrapper}`}>
                                        <div>
                                            <img src={item.heading}></img>
                                        </div>
                                        <div className={styles.text_wrap}>
                                            <div>
                                                <h4>{item.title}</h4>
                                                <br />
                                                <p>{item.description}</p>
                                            </div>
                                            <span>
                                          {item.readMoreText}
                                        </span>
                                        <a href="" className={styles.full_link}></a>
                                        </div>
                                     </div>
                                      )
                                  
                                  })
                                      

                            ):null}
                          
                            {/* <div className={`col-md-4 col-xs-6$styles.image_wrapper}`}>
                                    <div>
                                        <img src="https://ark.shalomworld.org/wp-content/uploads/2018/12/donate-car.jpg"></img>
                                    </div>
                                    <div className={styles.text_wrap}>
                                        <div>
                                            <h4>Car Donation</h4>
                                            <p>Your Old Car can Give New Life</p>
                                        </div>
                                        <span>
                                            Learn More
                                        </span>
                                        <a href="" className={styles.full_link}></a>
                                    </div>
                            </div>  
                            <div className={`col-md-4 col-xs-6$styles.image_wrapper}`}>
                                    <div>
                                        <img src="https://ark.shalomworld.org/wp-content/uploads/2018/12/donate-pledge.jpg"></img>
                                    </div>
                                    <div className={styles.text_wrap}>
                                        <div>
                                            <h4>Increase Your Existing Pledge</h4>
                                            <p>Call/Email Us for More Information</p>
                                        </div>
                                        <span>
                                            Learn More
                                        </span>
                                        <a href="" className={styles.full_link}></a>
                                    </div>
                            </div>    */}
                        </div>     
                        </Container>   
                    </section>
    )
}

export default WaysToGive;