
import React,{lazy} from 'react';
import Terms from '../components/Terms';
const Menu = lazy(()=>import('../components/Menu'));
const Subscription = lazy(()=>import('../components/Subscription'));
const Footer = lazy(()=>import('../components/Footer'));

const TermsandConditions = () => {
    return (
        <>
        <Menu/>
        <Terms/>
        <Subscription/>
        <Footer/>
        </>
    )
}
export default TermsandConditions;  