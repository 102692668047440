import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './StickyNewsHeader.module.scss';
import { fetchBlogCategories } from '../../actions/newsAction';
import NewsHeaderMobile from '../Mobile/NewsCategory';
import { isMobile } from 'react-device-detect';
import { useDimensions } from '../../logic/Dimentions';



const StickyNewsHeader = ({ fetchBlogCategories, news }) => {

    const { height, width } = useDimensions();

    useEffect(() => {
        if (news && news.length == 0)
            fetchBlogCategories();
    }, [])

    return (
        width >= 992 ? < div className = { styles.margin_wrap } > < div className = { styles.head_breadcrumbs } > < div className = "container" > < ul className = { styles.program_tab } > {
            news && news.length > 0 ? (
                news.map((newsCategory, index) => {
                    return ( <
                        li className = "active"
                        key = { index } > < a href = "/news/" > { newsCategory.title } < /a></li >
                    )
                })
            ) : null
        } <
        /ul> < /
        div > < /div > < /div > : < NewsHeaderMobile value = { news }
        / >
    )
}
const mapStateToProps = (state) => {
    return ({ news: state.news.blogCategories })
}
export default connect(mapStateToProps, { fetchBlogCategories })(StickyNewsHeader);