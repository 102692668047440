import {FETCH_LATEST_EPISODES,FETCH_CATEGORY_EPISODES,FETCH_LATEST_VIDEOS,FETCH_RELATED_VIDEOS,REMOVE_LATEST_RELATED_VIDEOS} from '../actions/types.js';

// const INTIAL_STATE = {
//     latestEpisodes:[],
//     // categoryEpisodes:[],
//     // latestVideos:[],
//     // relatedVideos:[],
//     // currentLatestPage:1,
//     // currentRelatedPage:1

// };

const INTIAL_STATE = [];

export default (state = INTIAL_STATE,action) => {
    switch(action.type){
        case FETCH_LATEST_EPISODES:
            let data = action.payload;
            return {...state,...data};
        // case FETCH_CATEGORY_EPISODES:
        //     if(action.page>1){
        //         return{
        //             ...state,
        //             categoryEpisodes: state.categoryEpisodes.concat(action.payload)
        //         }
        //     }
        //     else{
        //         return {
        //             ...state,
        //             categoryEpisodes : action.payload
        //         }
        //     }
        //     case FETCH_LATEST_VIDEOS:
        //         if(action.page>1){
        //             return{
        //                 ...state,
        //                 latestVideos: state.latestVideos.concat(action.payload),
        //                 currentLatestPage:action.page
        //             }
        //         }
        //         else{
        //             return {
        //                 ...state,
        //                 latestVideos : action.payload
        //             }
        //         }
        //         case FETCH_RELATED_VIDEOS:
        //             if(action.page>1){
        //                 return{
        //                     ...state,
        //                     relatedVideos: state.relatedVideos.concat(action.payload),
        //                     currentRelatedPage:action.page
        //                 }
        //             }
        //             else{
        //                 return {
        //                     ...state,
        //                     relatedVideos : action.payload
        //                 }
        //             }
        //         case REMOVE_LATEST_RELATED_VIDEOS:
        //             return{
        //                 ...state,
        //                 latestVideos:[],
        //                 relatedVideos:[]
        //             }
           
            

        default:
            return state;
    }
}