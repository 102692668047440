import {FETCH_LATEST_EPISODES,FETCH_LATEST_VIDEOS,FETCH_RELATED_VIDEOS,REMOVE_LATEST_RELATED_VIDEOS} from './types';
import salomapi from '../apis';
import BrowserPersistence from '../util/simplePersistance';

const storage = new BrowserPersistence();
export const fetchLatestEpisode =  (region) => async dispatch => {
    async function fetchFromServer() {
    try {
    const response = await salomapi.get('/latest/episodes?region='+region);
    let data = {['latestEpisodes']:response.data.episodes}
    dispatch({type:FETCH_LATEST_EPISODES,payload:data})
    await saveLatestEpisodes(data);
        }
    catch (error) {
    dispatch({type:FETCH_LATEST_EPISODES,payload:error});
    throw error;
        }
    }
    const localHeaderData = await retrieveLatestEpisodes();
    if(localHeaderData)
    dispatch({type:FETCH_LATEST_EPISODES,payload:localHeaderData})
    else
    await fetchFromServer();

    async function retrieveLatestEpisodes() {
        return storage.getItem('LatestEpisodes');
      }
    async function saveLatestEpisodes(data) {
        return storage.setItem('LatestEpisodes', data,86400);
      }
}

export const fetchCategoryEpisode =  (category,page,region) => async dispatch => {

    const response = await salomapi.get('category/episodes?region='+region+'&category='+category+'&page='+page+'&perpage=12');
    let data = {[category]:response.data.episodes}
    dispatch({type:FETCH_LATEST_EPISODES,payload:data})
    
}
export const fetchLatestVideo =  (id,zypeId,page,episodeId,episodes,region) => async dispatch => {

    let resp = []
    const response = await salomapi.get('/related/episodes?region='+region+'&id='+id+'&zypeId='+zypeId+'&page='+page+'&posts_per_page=6&episodeId='+episodeId);
    
    if(page===2 && episodes)
    resp = episodes.concat(response.data.episodes)
    else
    resp = response.data.episodes
    dispatch({type:FETCH_LATEST_VIDEOS,payload:resp,page:page})
    
}

export const fetchRelatedVideo =  (zypeId,tags,page,id,episodes,region) => async dispatch => {
    let resp = []
    const response = await salomapi.get('/tag/episodes?region='+region+'&zypeId='+zypeId+'&tags='+tags+'&page='+page+'&perpage=6&postId='+id);
    if(page===2)
    resp = episodes.concat(response.data.episodes)
    else
    resp = response.data.episodes

    dispatch({type:FETCH_RELATED_VIDEOS,payload:resp,page:page})
    
}
export const removeLatestRelatedVideo =  () => async dispatch => {
    dispatch({type:REMOVE_LATEST_RELATED_VIDEOS})
    
}